@import "../../../styles/utils";

.month-year-picker {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-top: 20px;

  &__container {
    border: 1px solid rgba($blueish-black, 0.16);
    box-shadow: 0 1px 3px rgba($blueish-black, 0.16);
    font-size: 14px;
    width: 100%;
  }

  &__label {
    color: $blueish-black;
    display: block;
    margin-bottom: 10px;
  }

  // Override default

  input {
    border: 1px solid rgba($blueish-black, 0.16);
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba($blueish-black, 0.16);
    color: $blueish-black;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    height: 2.5rem;
    line-height: 1.5;
    outline: none;
    padding: 10px 12px;
    width: 100%;

    &::placeholder {
      color: rgba($blueish-black, 0.4);
    }

    &:focus {
      border-color: rgba($blueish-black, 0.4);
    }
  }

  .react-datepicker__header {
    background-color: $white;
  }

  .react-datepicker-year-header {
    color: $blueish-black;
    border-bottom: 1px solid rgba($blueish-black, 0.16);
    padding: 12px 0;
  }

  .react-datepicker__month-wrapper {
    .react-datepicker__month-text {
      border-radius: 4px;
      color: $blueish-black;
      margin: 0;
      padding: 12px 0;
      width: 100px;

      &--keyboard-selected {
        background-color: $blueish-black;
        color: $white;
        font-weight: $font-weight-400;
      }
    }
  }

  .react-datepicker__navigation {
    top: 6px;
  }

  .react-datepicker__navigation-icon {
    &::before {
      border-color: $blueish-black;
    }

    &:hover {
      &::before {
        opacity: 0.6;
      }
    }
  }
}
