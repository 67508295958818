@import "../../../styles/utils";

$pill-tabs-nav-height: 32px;
$pill-tabs-button-size: 28px;

.pill-tabs {
  width: 100%;

  .rtabs {
    &__nav {
      height: $pill-tabs-nav-height;
      overflow-y: visible;

      &-button {
        height: $pill-tabs-button-size;
        width: $pill-tabs-button-size;

        .icon {
          &--small {
            font-size: 0.875rem;
          }
        }

        &-container {
          height: 100%;
        }
      }

      &-item {
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: $blueish-black;
        font-size: 14px;
        margin: 0;
        outline-offset: -2px;
        padding-bottom: 10px;
        transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

        &--disabled {
          color: rgba($blueish-black, 0.4);
          cursor: not-allowed;
        }

        &--separator {
          display: initial;
          margin: 0 8px;
          position: relative;
          top: 4px;
        }

        &--active {
          padding-bottom: 10px;
        }

        &--text {
          margin: 8px 0;
        }
      }

      &-underline {
        &--selected {
          background: $blueish-black;
          color: $white;
        }

        &--hide {
          display: initial;
        }
      }

      &-container {
        height: $pill-tabs-nav-height;
        overflow: visible;

        .rtabs__nav-item--separator:nth-last-child(2) {
          display: none;
        }
      }
    }

    &--touchable {
      .rtabs__nav-container {
        height: $pill-tabs-nav-height;
        overflow: auto;
      }
    }
  }
}
