@import "../../../styles/utils";

.content-loader {
  width: var(--content-width);

  &__circle {
    &--tiny {
      width: 24px;
    }

    &--normal {
      width: 40px;
    }

    &--large {
      width: 56px;
    }

    &--huge {
      width: 80px;
    }

    &--xl {
      width: 120px;
    }

    &--xxl {
      width: 136px;
    }
  }

  &-content {
    background: transparent;
    height: var(--content-height);
    overflow: hidden;
    padding: 0;
    position: relative;

    &--bg {
      background: $white;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba($blueish-black, 0.16);
      padding: 20px;
    }

    &__circle {
      background: transparent;
      border-radius: 50%;
      padding: 0;

      &--tiny {
        height: 24px;
      }

      &--normal {
        height: 40px;
      }

      &--large {
        height: 56px;
      }

      &--huge {
        height: 80px;
      }

      &--xl {
        height: 120px;
      }

      &--xxl {
        height: 136px;
      }
    }
  }

  &-item {
    animation-duration: 1.7s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: placeholderAnimate;
    animation-timing-function: linear;
    border-radius: 4px;
    height: 20px;
    width: 100%;
    z-index: 2;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &--round {
      border-radius: 4px;
    }

    &__circle {
      &--tiny {
        height: 24px;
      }

      &--normal {
        height: 40px;
      }

      &--large {
        height: 56px;
      }

      &--huge {
        height: 80px;
      }

      &--xl {
        height: 120px;
      }

      &--xxl {
        height: 136px;
      }
    }
  }
}

@keyframes placeholderAnimate {
  0% {
    background-color: rgba($blueish-black, 0.2);
  }

  50% {
    background-color: rgba($blueish-black, 0.32);
  }

  100% {
    background-color: rgba($blueish-black, 0.2);
  }
}
