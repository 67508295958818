@import "../../../styles/utils";

.record-payment {
  &__header {
    align-items: center;
    display: flex;
    margin-top: 16px;
  }

  &__wrapper {
    padding: 20px
  }

  &__info {
    display: flex;
    justify-content: space-between;

    &-card {
      margin-right: 20px;
      padding: 0;
      width: 100%;

      &:last-child {
        margin-right: 0;
      }
    }

    &-container {
      display: flex;
    }

    &--icon {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 20px;
    }

    &--amount {
      margin-right: 20px;
      padding: 20px;
      padding-left: 0;
    }

    &--title {
      color: rgba($blueish-black, 0.4);
      font-size: 14px;
      font-weight: $font-weight-700;
    }

    &--value {
      color: $blueish-black;
      font-size: 20px;
      font-weight: $font-weight-700;
    }
  }
}

.transaction-history {
  &__title {
    color: $blueish-black;
    font-size: 16px;
    font-weight: $font-weight-700;
    margin-top: 20px;
    text-transform: capitalize;
  }

  &__written_off_tag {
    background-color: rgba($blueish-black, 0.4);
  }
}
