@import "../../styles/utils";

.dashboard {
  padding: 31px 20px;

  &__popover {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &--wrapper {
      height: 140px;
      width: 120px;

      .emphasis-tag {
        margin-left: 0;
      }
    }
  }

  &__latest {
    &-title {
      font-size: 20px;
    }
  }

  &__admin {
    &-total-organisation {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      justify-content: space-between;
    }

    &-star-count {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }

    &-department {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      justify-content: space-between;
    }
  }

  &__education {
    &-card {
      margin: 20px;

      .table {
        &__head > tr > th {
          padding: 20px;
        }
      }

      .table {
        &__row {
          border: 1px solid rgba(0, 0, 0, 0.16);
          padding-bottom: 10px;
        }
      }

      .table__field {
        padding: 20px;
      }
    }
  }

  &__tasks,
  &__messages {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &-wrapper {
      margin-bottom: 10px;
    }

    &-tags {
      display: flex;
      font-size: 12px;
      justify-content: space-between;

      .emphasis-tag {
        margin-left: 0;
      }
    }

    &-title {
      font-size: 20px;
    }
  }

  &__messages {
    align-items: center;
    &-avatar {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .organization-name {
        color: $blueish-black;
        font-size: $font-14;
        font-weight: $font-weight-700;
      }

      .user-name {
        margin-left: 8px;
      }
    }

    &-date {
      font-size: $font-12;
      margin-left: 10px;
    }

    &-wrapper {
      .empty-placeholder {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 400px;
      }
    }
  }

  &__organisation,
  &__task,
  &__messages {
    &-card {
      margin-left: 30px;
      margin-top: 20px;
    }
  }

  &__organisation {
    margin-top: 26px;

    &-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &-title {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      &--info {
        margin-left: 20px;

        &__name {
          font-size: 20px;
          font-weight: 700;
        }

        &__email {
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
          margin-top: 5px;
        }
      }
    }

    &-wrapper {
      margin-top: 20px;

      .title {
        margin-top: 0;
      }
    }

    &-details {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &__name {
        font-size: 20px;
        font-weight: 600;
      }

      &__role {
        font-size: 12px;
        margin-top: 4px;
      }

      &__task-message-count {
        align-items: center;
        display: flex;
        margin-top: 10px;
      }

      &__message-count {
        margin-left: 20px;
      }

      &__task-count {
        align-items: center;
        display: flex;

        .badge {
          cursor: pointer;
          height: 1px;
          margin-left: 5px;
          width: 1px;
        }
      }

      .organisation {
        &__bio-cuisine-status {
          svg {
            fill: $green;
          }
        }
      }
    }
  }

  &__tasks {
    &-dates {
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      margin-top: 6px;
    }

    &-see-all {
      color: $info;
      cursor: pointer;
    }
  }

  &__spinner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__unauthorized-warning {
    margin-top: 15px;
    text-align: center;

    .rcl-alert {
      display: inline-block;
      padding: 10px;

      &-container {
        justify-content: center;
      }
    }
  }

  &__recent-organisations {
    &-attributes {
      display: flex;
      justify-content: space-between;
    }

    .card__body-section {
      font-weight: 400;
    }

    .header {
      margin-bottom: 8px;
    }

    hr {
      margin-bottom: 20px;
      opacity: 0.4;

      &:last-of-type {
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }
  }

  &__upcoming-control {
    margin-bottom: 10px;

    &-schedule,
    &-organisation {
      display: flex;
      justify-content: space-between;
    }

    &-organisation {
      margin-top: 20px;
    }

    .card__body-section {
      font-weight: 400;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &--boldest-font {
    font-weight: 700;
  }

  &--disabled {
    color: $disabled;
  }

  &__container {
    display: flex;
    gap: 20px;

    &-item {
      flex: 1;
    }
  }
}
