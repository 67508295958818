@import "../../../styles/utils";

.title,
.header,
.text {
  &--boldest {
    font-weight: 700;
  }

  &--bold {
    font-weight: 600;
  }

  &--medium {
    font-weight: 500;
  }

  &--normal {
    font-weight: 400;
  }

  &--light {
    font-weight: 300;
  }
}

.title {
  margin: 0;

  &--level-1 {
    font-size: 16px;
  }

  &--level-2 {
    font-size: 4rem;
  }
}

.header {
  margin: 0;

  &--level-1 {
    font-size: 48px;
  }

  &--level-2 {
    font-size: 40px;
  }

  &--level-3 {
    font-size: 32px;
  }

  &--level-4 {
    font-size: 28px;
  }

  &--level-5 {
    font-size: 24px;
  }

  &--level-6 {
    font-size: 20px;
  }
}

.text {
  &--level-1 {
    font-size: 14px;
  }

  &--level-2 {
    font-size: 12px;
  }

  &--level-3 {
    font-size: 10px;
  }
}
