@import "../../../styles/utils";

.rcl-popover {
  $popover-padding: 8px;
  animation: scale-opacity 0.2s 0.08s 1 forwards;
  background: $white;
  border: 1px solid rgba($blueish-black, 0.16);
  border-radius: 3px;
  color: $blueish-black;
  display: block;
  filter: drop-shadow(0 1px 3px rgba($tertiary-accent, 0.16));
  font-family: "Open Sans", sans-serif;
  max-width: 100%;
  opacity: 0;
  padding: $popover-padding;
  pointer-events: auto;
  position: absolute;
  transition: 0.1s all ease;
  z-index: 10000;

  &,
  * {
    box-sizing: border-box;
  }

  &__parent {
    box-sizing: border-box;
    display: inline-block;
  }

  &__content {
    display: block;
    max-width: 100%;
    overflow-y: auto;
    scrollbar-color: $blueish-black transparent; // overrides default css for mozilla firefox
    scrollbar-width: thin; // overrides default css for mozilla firefox

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 3px;
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      border-radius: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($blueish-black, 0.16);
      border-radius: 3px;
      transition: 0.1s background-color ease;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $blueish-black;
        transition: 0.1s background-color ease;
      }
    }

    &--small {
      max-height: 180px;
      width: 200px;
    }

    &--medium {
      max-height: 300px;
      width: 300px;
    }

    &--large {
      max-height: 380px;
      width: 340px;
    }
  }

  &__tip {
    display: block;
    height: var(--popover-tip-height);
    position: absolute;
    stroke-width: 0;
    transition: 0.1s all ease;
    width: var(--popover-tip-width);

    svg {
      display: block;
    }

    &--bottom,
    &--top {
      left: 50%;
      transform: translateX(-50%);
    }

    &--bottom-left,
    &--top-left {
      left: $popover-padding;
    }

    &--bottom-right,
    &--top-right {
      right: $popover-padding;
    }

    &--bottom,
    &--bottom-left,
    &--bottom-right,
    &--stretch-bottom {
      top: calc(-1 * var(--popover-tip-height) - 1px);
    }

    &--top,
    &--top-left,
    &--top-right,
    &--stretch-top {
      bottom: calc(-1 * var(--popover-tip-height) + 1px);

      svg {
        transform: rotate(180deg);
      }
    }

    &--left,
    &--right {
      top: 50%;
      transform: translateY(-50%);
    }

    &--left-top,
    &--right-top {
      top: $popover-padding;
    }

    &--left-bottom,
    &--right-bottom {
      bottom: $popover-padding;
    }

    &--left,
    &--left-top,
    &--left-bottom {
      right: calc(-1 * (var(--popover-tip-width) / 2) - (var(--popover-tip-height) / 2));

      svg {
        transform: rotate(90deg);
      }
    }

    &--right,
    &--right-top,
    &--right-bottom {
      left: calc(-1 * (var(--popover-tip-width) / 2) - (var(--popover-tip-height) / 2));

      svg {
        transform: rotate(270deg);
      }
    }
  }

  &--bottom {
    transform-origin: top center;

    &-left {
      transform-origin: top left;
    }

    &-right {
      transform-origin: top right;
    }
  }

  &--top {
    transform-origin: bottom center;

    &-left {
      transform-origin: bottom left;
    }

    &-right {
      transform-origin: bottom right;
    }
  }

  &--right {
    transform-origin: center left;

    &-top {
      transform-origin: top left;
    }

    &-bottom {
      transform-origin: bottom left;
    }
  }

  &--left {
    transform-origin: center right;

    &-top {
      transform-origin: top right;
    }

    &-bottom {
      transform-origin: bottom right;
    }
  }
}

@keyframes scale-opacity {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
