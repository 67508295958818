@import "../../../styles/utils";

.user {
  &__education-loader {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &--first-row {
      :first-child {
        column-gap: 20px;
      }

      :last-child {
        column-gap: 10px;
      }
    }
  }
}
