@import "../../../styles/utils.scss";

$step-index-width: 40px !default;
$step-connector-padding: 5px !default;
$tertiary-accent-lighter: rgba($tertiary-accent, 0.16);

.stepper {
  * {
    box-sizing: border-box;
  }

  background-color: $white;
  border-radius: 3px;
  box-shadow: 0 1px 3px $tertiary-accent-lighter;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;

  &-sticky {
    background-color: $greyish-white;
    box-shadow: none;
    padding: 0;
    position: sticky;
    top: -20px;
    z-index: 5;
  }

  &__body {
    align-items: center;
    display: flex;
    padding: 20px 0;
    transition: 0.2s ease-in-out;

    &-step {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      transition: opacity 0.3s ease;
      width: 100%;

      &-index,
      &-name {
        align-items: center;
        display: flex;
        font-size: 14px;
        justify-content: center;
        width: 100%;
      }

      &-index {
        background-color: $white;
        border: 10px solid $blueish-black-16;
        border-radius: 50%;
        color: $white;
        height: $step-index-width;
        padding: 2px;
        width: $step-index-width;
      }

      &-name {
        color: $disabled;
        margin-top: 8px;
      }

      &-check-icon {
        height: 10px;
        width: 10px;

        svg {
          fill: $white;
        }
      }

      &-connector {
        border-top: 2px dashed #00393f29;
        left: calc(-50% + #{$step-index-width} / 2 + #{$step-connector-padding});
        position: absolute;
        right: calc(50% + #{$step-index-width} / 2 + #{$step-connector-padding});
        top: $step-index-width / 2;

        &::after {
          border-top: 1px solid $white;
          content: "";
          left: 0;
          position: absolute;
          right: 0;
          top: -1px;
        }
      }

      &--active {
        .stepper__body-step {
          &-index {
            background-color: $white;
            border: 12px solid $blueish-black-16;
            padding: 2px;
          }

          &-name {
            color: $blueish-black;
          }

          &-index {
            border: 10px solid rgba($dark-blue, 0.7);
          }

          &-check-icon {
            svg {
              fill: rgba($dark-blue, 0.7) !important;
            }
          }
        }
      }

      &--done {
        .stepper__body-step {
          &-index {
            background-color: $white;
            border: 12px solid $green;
            padding: 2px;
          }

          &-check-icon {
            svg {
              fill: $green !important;
            }
          }

          &-connector {
            border-color: $blueish-black-16;
          }
        }
      }

      &--warning {
        .stepper__body-step {
          &-index {
            background-color: $white;
            border: 12px solid $blueish-black-16;
            padding: 2px;
          }

          &-name {
            color: $blueish-black;
          }

          &-index {
            border: 10px solid $danger;
          }

          &-check-icon {
            svg {
              fill: $danger !important;
            }
          }
        }
      }
    }
  }

  &__header,
  &__footer {
    border-top: 1px solid $tertiary-accent-lighter;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    width: 100%;

    &-sticky {
      background-color: $white;
      border-radius: 3px;
      border: none;
      box-shadow: 0 1px 3px $tertiary-accent-lighter;
      padding: 12px 20px;
    }

    &-step-description {
      align-items: center;
      display: flex;
      font-size: $font-14;
      font-weight: $font-weight-700;
      line-height: 1.5;
    }

    &-actions {
      display: flex;
      margin-left: auto;

      &-button {
        font-size: 14px;
        font-weight: 400;
        margin: 0;

        &--pre {
          margin-right: 8px;
        }
      }
    }
  }

  &__header {
    border-top: none;
    margin-top: 20px;
    padding: 0;
  }

  &--vertical {
    display: flex;
    flex-wrap: wrap;
    width: 250px;

    .stepper__body {
      align-items: stretch;
      flex-wrap: wrap;
      min-height: 450px;
      padding: 0;

      &-step {
        align-content: center;
        min-height: 100px;

        &-connector {
          border-left: 2px dashed $ghost-gray;
          border-top: none;
          bottom: calc(50% + #{$step-index-width});
          left: 50%;
          position: absolute;
          right: auto;
          top: calc(-50% + #{$step-index-width});

          &::after {
            border-left: 1px solid $white;
            border-top: none;
            bottom: 0;
            content: "";
            left: -1px;
            position: absolute;
            right: auto;
            top: 0;
          }
        }
      }
    }

    .stepper__footer {
      border-top: none;
      padding: 0 0 20px;

      &-actions {
        display: flex;
        width: 100%;

        > * {
          flex-grow: 1;
        }
      }
    }

    .stepper__body-step--done {
      .stepper__body-step {
        &-connector {
          border-color: $tertiary-accent;
        }
      }
    }
  }
}
