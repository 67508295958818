@import "../../styles/utils.scss";

.recognition {
  &__table {
    .table__field {
      &--header {
        .rcl-checkbox {
          &__label {
            font-weight: $font-weight-700;
          }
        }
      }
    }

    tr {
      height: 70px;
    }

    td {
      &:last-of-type {
        width: 150px;
      }

      .link {
        font-size: 10px;
      }

      .user__action {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &__marks {
    &--wrapper {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 4px;
      margin-top: 20px;

      .icon {
        margin-right: 5px;
        margin-top: 2px;
      }
    }
  }

  &__select-all,
  &__clear-all {
    cursor: pointer;
  }

  &__vertical-line {
    background-color: $blueish-black-16;
    height: 23px;
    margin-right: 20px;
    width: 2px;
  }

  &__selection-text {
    font-size: $font-14;
    font-weight: $font-weight-600;
    margin-right: 20px;
  }
}
