@import "../../../styles/utils";

.yes-no-input {
  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    &--text {
      display: inline;
      font-weight: $font-weight-400;
      margin-right: 8px;
    }

    &--error {
      display: inline;
      vertical-align: middle;
      font-size: $font-16;
    }
  }

  &__options {
    display: flex;
    font-weight: $font-weight-400;

    &-checkbox {
      position: relative;

      &::after {
        background-color: $white;
        border: 1.5px solid $blueish-black;
        border-radius: 3px;
        content: "";
        cursor: pointer;
        display: inline-block;
        height: 14px;
        width: 14px;
      }
    }
  }

  &__option {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &-checkbox {
      position: relative;
      width: 14px;
      height: 14px;
      border: 1.5px solid $blueish-black;
      border-radius: 3px;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: rgba($blueish-black, 0.24);
      }

      &--selected {
        background-color: $blueish-black;

        &:hover {
          background-color: $blueish-black;
        }
      }

      &--icon {
        svg {
          fill: $white;
          height: 8px !important;
          width: 8px !important;
        }
      }
    }
  }
}
