@import "../../styles/utils";

.status {
  &__step {
    align-items: center;
    display: flex;
    height: 30px;
    justify-content: flex-start;
    width: 12%;

    &-container {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &-text {
      font-size: 10px;
      font-weight: $font-weight-200;
      position: absolute;
      top: 20px;
    }

    &-icon {
      svg {
        font-size: 0.5rem;
      }

      &--disabled {
        .icon__content {
          svg {
            fill: $disabled-light;
          }
        }
      }

      &--success {
        .icon__content {
          svg {
            fill: $pastal-green;
          }
        }
      }

      &--submitted {
        .icon__content {
          svg {
            fill: $info;
          }
        }
      }

      &--grace-period {
        .icon__content {
          svg {
            fill: $warning;
          }
        }
      }

      &--expired {
        .icon__content {
          svg {
            fill: $normal-red;
          }
        }
      }
    }

    &-percentage {
      &--text {
        font-size: 10px;
        font-weight: 200;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      padding: 20px !important;
    }

    &-star-count {
      font-size: 10px;
      font-weight: 700;
      margin-top: 8px;
    }

    &-certificate {
      bottom: 20px;
      font-size: 10px;
      font-weight: 700;
      position: absolute;
    }

    &-certified {
      font-size: 10px;
      font-weight: 200;
      margin-bottom: 8px;
      width: max-content;
    }

    &-star {
      align-items: center;
      bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: fit-content;
      position: relative;
    }

    .organisation {
      &__bio-cuisine-status {
        margin: 0 10px;

        .icon {
          svg {
            fill: $pastal-green;
          }
        }
      }
    }

    &-title {
      align-items: flex-start;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 4px;
      min-width: 200px;

      &--wrapper {
        display: flex;
        flex-direction: row;
      }
    }

    &-icon {
      border-radius: 100%;
      width: fit-content;

      .icon {
        &__content {
          background-color: $white;
          height: 1rem;
          width: 1rem;
        }
      }

      &--disabled {
        border: 8px solid $disabled-light;
      }

      &--expired {
        border: 8px solid $normal-red;
      }

      &--submitted {
        border: 8px solid $info;
      }

      &--success {
        border: 8px solid $pastal-green;
      }

      &--grace-period {
        border: 8px solid $warning;
      }
    }

    &-bar {
      background-color: $pastal-green;
      height: 4px;
      width: 100%;

      &--disabled {
        background-color: $disabled-light;

        &-line {
          background-color: $disabled-light;
        }
      }
    }

    &-card {
      box-shadow: 0 1px 3px rgba(87, 7, 51, 0.12);
      padding-top: 30px !important;
    }
  }

  &__header {
    font-size: $font-12;
    margin-bottom: 8px;
  }
}
