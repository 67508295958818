@import "../../../styles/utils";

.user {
  &__contact-loader {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .card {
      box-shadow: $form-card-shadow;
    }

    &--first,
    &--second {
      :first-child {
        column-gap: 20px;
      }

      :last-child {
        column-gap: 10px;
      }
    }
  }
}
