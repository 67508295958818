@import "../../styles/utils";

.task {
  &__wrapper {
    margin-right: 20px;

    &-navigate {
      &--link {
        .icon {
          font-size: 0.625rem;
          padding-left: 8px;
        }
      }

      .sanitized-html {
        a {
          color: $blueish-black;
          pointer-events: none;
        }
      }

      &--title {
        margin-right: 10px;
      }
    }
  }

  &__assignee,
  &__reporter {
    color: $disabled;
  }

  &__container {
    .organization {
      &-name {
        color: $info;
      }
    }
  }

  &__details {
    &-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &-content {
      max-width: 760px;

      &--task {
        margin-top: 20px;
      }
    }

    &--boldest-font {
      font-weight: $font-weight-700;
    }
  }

  &__top {
    margin-top: 8px;
  }

  &__full {
    margin-top: 20px;
  }

  &__wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__head {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .user-name {
      color: $primary-text;
      margin-left: 10px;
    }

    &--time {
      margin-left: 8px;
    }
  }

  &__icon {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;

    &--clear {
      margin-left: 10px;
    }
  }

  &__popover {
    font-size: 14px;
    font-weight: 400;

    a {
      color: $blueish-black;
    }

    &-wrapper {
      .rcl-popover {
        &__content {
          padding: 0;
        }
      }
    }

    &-content {
      padding: 12px 4px;

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &--hide {
        display: none;
      }

      &:hover {
        background-color: $blueish-black-8;
        cursor: pointer;
        opacity: 1;
      }

      &:active {
        background-color: $blueish-black-16;
        font-weight: $font-weight-700;
      }
    }
  }

  &__tab {
    padding-left: 20px;
  }

  &__create-search {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__create {
    &-card {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      box-shadow: 0 1px 12px $grey-shade;
      border-radius: 4px;

      .task__create-card--box {
        padding: 20px 0 0;
      }

      .card--v2__wrapper {
        padding: 5px;
      }
    }

    &--priority {
      padding-right: 0 !important;
      margin-top: 2px;
    }

    &--title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .icon-button {
        margin-right: 0;
        padding-right: 0;
      }
    }

    &--form {
      align-items: center;
      display: flex;
      justify-content: space-between;
      min-width: 520px;

      &__wrapper {
        &-label {
          align-items: center;
          color: $blueish-black;
          font-weight: $bolder-font-weight;
          margin: 0 0 12px;
        }
      }

      &__header {
        font-size: 20px;
        font-style: normal;
        margin: 0;
        text-transform: capitalize;
      }

      &__checkbox {
        display: flex;
        flex-direction: row;

        .rcl-checkbox:not(:first-of-type) {
          margin-left: 20px;
        }
      }

      &__field-error {
        color: $normal-red;
        font-size: 12px;
        margin-left: 5px;
        margin-top: 5px;
        text-transform: capitalize;
      }
    }

    &--modal {
      .rcl-modal {
        &__container {
          max-width: 520px;
        }

        &__content {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &--footer {
      display: flex;
      justify-content: flex-start;
      margin: 30px 0 45px;

      &__cancel-btn {
        margin-left: 10px;
      }
    }

    &--horizontal-row {
      margin-top: 20px;
      opacity: 0.3;
    }
  }
}

.comment {
  &__show-button {
    margin-top: 12px;
    width: 100%;

    &--hide {
      display: none;
    }
  }
}
