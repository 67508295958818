@import "../../../styles/utils";

.badge {
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  &--default {
    background: $florid-tar;
  }

  &--active {
    background: $earthly-royal;
  }

  &--inactive {
    background: $prime-marble;
  }

  &--new {
    background: $rightful-chocolate;
  }

  &--warning {
    background: $warning;
  }

  &--small {
    border-radius: 100px;
    height: auto;
    min-height: 12px;
    min-width: 12px;
    width: auto;
  }

  &--normal {
    height: 24px;
    width: 24px;
  }

  &__number {
    color: $white;
    font-size: 10px;
    margin-top: 1px;

    &--small {
      padding: 3px 6px;
    }
  }
}
