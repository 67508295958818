@import "styles/utils";

.image-modal {
  &__wrapper {
    cursor: pointer;
  }

  &__overlay {
    align-items: center;
    background: $blueish-black-60;
    display: flex;
    inset: 0;
    justify-content: center;
    opacity: 1;
    position: fixed;
    transition: visibility 0.5s, opacity 0.5s;
    visibility: visible;
    z-index: 1000;

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;

    .icon-button {
      position: relative;
      top: -150px;
    }

    > img {
      height: 300px;
      width: 300px;
    }
  }
}
