@import "../../../styles/utils";

.task-list__item {
  align-items: center;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba($black, 0.08);
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  min-height: auto;
  padding: 16px 20px;
  width: 100%;

  &-user {
    align-items: center;
    display: flex;
    margin-top: 4px;

    .user-name {
      color: $primary-text;
      margin-left: 10px;
    }
  }

  &-reporter {
    align-items: center;
    display: flex;

    .user-name {
      color: $primary-text;
      margin-left: 10px;
    }
  }

  &-avatar {
    .avatar__text {
      display: none;
    }

    &--organisation {
      color: rgba($blueish-black, 0.4);
      display: flex;
      margin-right: 8px;
    }

    .user-name {
      color: $primary-text;
    }
  }

  .task-list__item-details {
    display: none;
  }

  &-title {
    color: $primary-text;
    margin-right: auto;
    padding-top: 2px;

    @include viewport-is(tab-mobile) {
      order: 2;
    }

    &--checked {
      color: $posh-sorrel;
      text-decoration: line-through;
    }

    &--expanded {
      font-size: $font-20;
      font-weight: $font-weight-700;
      height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 60%;

      .sanitized-html {
        display: inline-block;
        height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80%;
      }
    }
  }

  &-checkbox,
  &-tag {
    align-items: center;
    display: flex;
    margin-right: 15px;
  }

  &-tag {
    @include viewport-is(tab-mobile) {
      margin-right: auto;
    }

    .emphasis-tag {
      margin-left: 0;
    }
  }

  &-date {
    color: $primary-text;
    font-size: 0.75rem;
    margin-right: 15px;
    padding-top: 2px;

    &--text {
      color: $disabled;
    }

    &--with-no-margin {
      margin-right: 0;
    }
  }

  &-icons {
    margin-left: 11px;

    &--hide {
      margin-left: 0;
    }
  }

  &--expanded {
    .task-list__item-details {
      display: block;
      width: 100%;
    }

    .task-list__item {
      &-largeTitle {
        color: $primary-text;
        font-size: 20px;
        font-weight: $bold-font-weight;
        margin-bottom: 6px;
        margin-top: 26px;
        text-align: left;
      }

      &-assosiatedOrder {
        color: $primary-text;
        font-size: 12px;
        margin-bottom: 30px;
        text-align: left;
      }

      &-description {
        color: $primary-text;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 33px;
        text-align: left;
      }

      &-avatars {
        display: flex;
        justify-content: space-between;

        &-assignee,
        &-reporter {
          display: flex;
          flex-direction: column;

          &-name {
            margin-bottom: 20px;
          }

          &-label {
            color: $primary-text;
            font-size: 12px;
            font-weight: $bold-font-weight;
            margin-bottom: 10px;
            margin-top: 4px;
          }

          .avatar__text-name {
            color: $primary-text;
          }
        }

        &-reporter {
          &-label {
            text-align: right;
          }
        }
      }

      &-times {
        display: flex;
        justify-content: space-between;

        > div {
          color: $blueish-black-40;
          font-size: 12px;
        }
      }
    }
  }
}
