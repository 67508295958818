@import "../../../styles/utils";

.organisation-overview {
  &__content {
    column-gap: 15px;
  }

  &__wrapper {
    > div {
      &:nth-child(n + 4) {
        margin-top: 5px;
      }

      &:nth-child(5) {
        padding: 15px 20px;
      }

      &:nth-child(2) {
        padding: 0 20px;
      }
    }
  }

  &__item {
    box-shadow: 0 1px 3px rgba(87, 7, 51, 0.12);
    display: flex;
    min-height: 220px;

    &--no-left-margin {
      margin-left: 0;
    }

    &--disabled {
      color: $blueish-black;
      opacity: 0.5;
      pointer-events: none;
    }

    .card--v2__wrapper {
      width: 100%;

      > div {
        height: 100%;
      }

      .card__body-section {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;

    &-header {
      align-items: center;
      display: flex;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 19px;

      &-text {
        margin-right: 6px;
      }

      &--icon {
        &__success {
          .icon {
            &__content {
              background-color: $pastal-green;

              svg {
                fill: $white;
              }
            }
          }
        }

        &__danger {
          .icon {
            &__content {
              background-color: $normal-red;

              svg {
                fill: $white;
              }
            }
          }
        }

        &__warning {
          .icon {
            &__content {
              background-color: $warning;

              svg {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }

  &__time {
    &-date {
      font-size: 12px;
      font-weight: $font-weight-400;

      &--expired {
        color: $normal-red;
      }
    }

    &-remaining {
      color: $disabled;
      font-size: 10px;
      font-weight: 200;
      margin-left: 10px;
    }
  }

  &__complete {
    align-items: center;
    display: flex;
    height: 36px;

    &-number {
      font-size: 24px;
    }

    &-text {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__not-complete {
    margin: 25px 0;

    &--anchor {
      color: $info;
      cursor: pointer;
    }
  }

  &__status {
    font-size: 12px;
    font-weight: $font-weight-400;
  }

  &__details {
    color: $sky-blue;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    margin-top: 4px;
    width: fit-content;
  }

  &__add-good {
    margin-left: 11px;
  }

  &__goods-completed {
    font-weight: $font-weight-400;
  }

  &-text {
    &__wrapper {
      &-status {
        &--text {
          font-weight: $font-weight-700;
          text-transform: capitalize;
        }
      }
    }
  }
}
