@import "../../../styles/utils";

.text-field {
  font-family: "Open Sans", sans-serif;
  position: relative;

  &__box {
    background: $white;
    border: 0 solid $blueish-black-16;
    border-radius: 4px;
    box-shadow: 0 1px 3px $blueish-black-8;
    box-sizing: border-box;
    color: $primary-text;
    font-family: "Open Sans", sans-serif;
    font-size: $rcl-font-14;
    height: 100%;
    line-height: $base-line-height;
    outline: none;
    padding: 8px 16px 12px 8px;
    resize: none;
    width: 100%;

    &::placeholder {
      color: $blueish-black-24;
      font-family: "Open Sans", sans-serif;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($tertiary-accent, 0.08);
      border-radius: 3px;
    }

    &::-webkit-scrollbar {
      background-color: rgba($tertiary-accent, 0.08);
      border-radius: 3px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($tertiary-accent, 0.16);
      border-radius: 3px;
    }

    &:hover {
      border: 1px solid $polo-blue;
    }

    &:focus {
      border: 1px solid $tertiary-accent;
    }

    &-tooltip-wrapper {
      position: absolute;
      right: 3%;
      top: 45%;
    }

    &-tooltip {
      &.rcl-tooltip.rcl-popover {
        padding: 6px 10px;

        .rcl-popover__content {
          font-size: 12px;
        }

        .rcl-popover__tip {
          &--bottom-left,
          &--top-left {
            left: $input-tooltip-tip-offset;
          }

          &--bottom-right,
          &--top-right {
            right: $input-tooltip-tip-offset;
          }

          &--left-top,
          &--right-top {
            top: calc(#{$input-tooltip-tip-offset} * 2);
          }

          &--left-bottom,
          &--right-bottom {
            bottom: calc(#{$input-tooltip-tip-offset} * 2);
          }
        }
      }
    }
  }

  &--tiny,
  &--small {
    &,
    &::placeholder {
      font-size: $rcl-font-14;
    }
  }

  &--large {
    &,
    &::placeholder {
      font-size: $rcl-font-20;
    }

    .text-field__box-tooltip {
      font-size: $rcl-font-14;
    }
  }

  &--huge {
    &,
    &::placeholder {
      font-size: $rcl-font-24;
    }

    .text-field__box-tooltip {
      font-size: $rcl-font-14;
    }
  }

  &--disabled {
    border-color: $periwinkle-gray;
    opacity: 0.5;
    pointer-events: none;

    ~ * svg {
      opacity: 0.2;
    }
  }

  &--error,
  &--error:hover,
  &--error:focus {
    border-color: $danger;
  }

  &-holder {
    position: relative;
    border: 1px solid $blueish-black-16;
    box-shadow: 0 1px 3px $blueish-black-8;
    border-radius: 4px;

    &--tiny {
      height: 48px;
    }

    &--small {
      height: 60px;
    }

    &--large {
      height: 72px;
    }

    &--huge {
      height: 84px;
    }
  }

  &__label {
    align-items: center;
    color: $blueish-black;
    display: flex;
    font-weight: $bolder-font-weight;
    margin: 0 0 0.3rem;
    line-height: 19px;
    letter-spacing: -0.02em;

    &--tiny,
    &--small {
      font-size: $rcl-font-14;
    }

    &--large {
      font-size: $rcl-font-20;
    }

    &--huge {
      font-size: $rcl-font-24;
    }

    &--disabled {
      pointer-events: none;
    }

    &--required {
      &::after {
        color: $blueish-black;
        content: "*";
        margin-left: 5px;
      }
    }
  }
}
