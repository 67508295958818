@import "../../../styles/utils";

$tooltip-tip-offset: 4px;

@mixin change-arrow-color($color, $change-border: true) {
  &.rcl-popover {
    background-color: $color;

    @if $color == $warning {
      color: $blueish-black;
    }

    @if $change-border {
      border: 1px solid $color;
    }

    .rcl-popover__tip {
      svg {
        path {
          &:first-of-type {
            fill: $color;
          }

          &:last-of-type {
            @if $change-border {
              fill: $color;
            }
          }
        }
      }
    }
  }
}

.rcl-tooltip {
  &__title {
    font-weight: $boldest-font-weight;
    margin-bottom: 5px;
  }

  &__content {
    font-weight: $bolder-font-weight;

    &--small {
      font-size: 14px;
    }

    &--tiny {
      font-size: 12px;
    }
  }

  &.rcl-popover {
    color: $white;
    line-height: 1.5;
    width: auto;
    z-index: 20000;

    .rcl-popover__content {
      padding: 0;
      width: 100%;
    }

    .rcl-popover__tip {
      &--bottom-left,
      &--top-left {
        left: $tooltip-tip-offset;
      }

      &--bottom-right,
      &--top-right {
        right: $tooltip-tip-offset;
      }

      &--left-top,
      &--right-top {
        top: calc(#{$tooltip-tip-offset} * 2);
      }

      &--left-bottom,
      &--right-bottom {
        bottom: calc(#{$tooltip-tip-offset} * 2);
      }
    }
  }

  &--medium {
    &.rcl-popover {
      max-width: 400px;
      padding: 16px;
    }
  }

  &--small {
    &.rcl-popover {
      max-width: 250px;
      padding: 12px;
    }
  }

  &--tiny {
    &.rcl-popover {
      max-width: 160px;
      padding: 4px 8px;
    }
  }

  &--dark {
    @include change-arrow-color($cool-gray-3);
  }

  &--accent {
    @include change-arrow-color($tertiary-accent);
  }

  &--important {
    @include change-arrow-color($secondary-accent);
  }

  &--disabled {
    @include change-arrow-color($secondary-text);
  }

  &--inverse {
    @include change-arrow-color($white, false);

    &.rcl-popover {
      color: $primary-text;
    }
  }

  &--success {
    @include change-arrow-color($success);
  }

  &--warning {
    @include change-arrow-color($warning);
  }

  &--danger {
    @include change-arrow-color($danger);
  }
}
