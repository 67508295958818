.charta-validity {
  display: flex;
  flex-flow: column;

  &__tag {
    margin-bottom: 5px;
  }

  &__period {
    margin-bottom: 20px;
  }

  &__emphasis-tag {
    margin-bottom: 8px;
  }
}
