@import "../../../styles/utils";

.approval-modal {
  &__description {
    margin-bottom: 20px;
  }

  &__rating-type {
    line-height: 20px;
    margin-bottom: 6px;

    &--bold {
      font-weight: $font-weight-700;
    }
  }

  &__stars {
    margin-bottom: 16px;

    .icon svg {
      fill: $green;
    }

    .icon-button:hover svg {
      fill: $green;
    }
  }

  &__rating-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
