@import "../../styles/utils";

.default-spinner {
  align-items: center;
  display: flex;
  justify-content: center;

  &--overview {
    height: 100%;
  }

  &--user-edit {
    height: 90%;
  }

  &--center {
    height: 45vh;
  }
}
