@import "../../../styles/utils";

.select-box-wrapper {
  border: 1px solid $blueish-black-16;
  border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 1px 3px $blueish-black-8;
  display: inline-block;
  font-weight: $bolder-font-weight;
  line-height: $base-line-height;
  position: relative;
  width: 100%;

  .select-box-container {
    width: 100%;
    &--not-empty.select-box-container--clearable {
      .select-box__dropdown-indicator {
        display: none;
      }
    }
  }

  &--full {
    width: 100%;

    .select-box-container {
      width: 100%;
    }
  }
}

.select-box {
  &__input {
    input {
      color: $blueish-black;

      &,
      &::placeholder {
        font-weight: $bolder-font-weight;
      }
    }
  }

  &__label {
    color: $blueish-black;
    display: block;
    font-weight: $bolder-font-weight;
    letter-spacing: -0.02em;
    line-height: 19px;
    margin-bottom: 6px;

    &--required {
      &::after {
        color: $blueish-black;
        content: "*";
        margin-left: 5px;
      }
    }

    &,
    &--small,
    &--tiny {
      font-size: $rcl-font-14;
    }

    &--large {
      font-size: $rcl-font-20;
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__tooltip-wrapper {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);

    > .rcl-popover__parent {
      display: block;
      font-size: 16px;
    }

    .icon--tertiary svg {
      fill: $danger;
      font-size: 20px;
    }
  }

  &__tooltip {
    &.rcl-tooltip.rcl-popover {
      padding: 6px 10px;

      .rcl-popover__content {
        font-size: 12px;
      }

      .rcl-popover__tip {
        &--bottom-left,
        &--top-left {
          left: $input-tooltip-tip-offset;
        }

        &--bottom-right,
        &--top-right {
          right: $input-tooltip-tip-offset;
        }

        &--left-top,
        &--right-top {
          top: calc(#{$input-tooltip-tip-offset} * 2);
        }

        &--left-bottom,
        &--right-bottom {
          bottom: calc(#{$input-tooltip-tip-offset} * 2);
        }
      }
    }
  }

  &__width {
    &--tiny {
      .select-box__control,
      .select-box__menu {
        width: 100%;
      }
    }

    &--small {
      .select-box__control,
      .select-box__menu {
        width: 100%;
      }
    }

    &--large {
      .select-box__control,
      .select-box__menu {
        width: 100%;
      }
    }

    &--huge {
      .select-box__control,
      .select-box__menu {
        width: 100%;
      }
    }

    &--full {
      .select-box__control,
      .select-box__menu {
        width: 100%;
      }
    }
  }

  &-container {
    display: inline-block;

    &--tiny {
      .select-box__input {
        bottom: 2px;
        position: relative;
      }

      .select-box__menu-notice {
        &--no-options {
          font-size: $rcl-font-14;
        }
      }
    }

    &--small {
      .select-box__menu-notice {
        &--no-options {
          font-size: $rcl-font-14;
        }
      }
    }

    &--large {
      .select-box__menu-notice {
        &--no-options {
          font-size: $rcl-font-20;
        }
      }
    }

    .select-box__control {
      background-color: $white;
      border: 0;
      box-shadow: 0 1px 3px rgba($tertiary-accent, 0.16);

      &--is-focused {
        background: $white;
        border-color: $polo-blue;
        box-shadow: 0 1px 3px rgba($tertiary-accent, 0.16);
      }

      &:hover {
        background: $white;
        border-color: $polo-blue;
      }

      &--is-disabled {
        border-color: $periwinkle-gray;
        opacity: 0.5;
      }
    }

    .select-box__indicator {
      padding: 8px 12px;

      &-separator {
        width: 0;
      }
    }

    .select-box__menu {
      box-shadow: 0 1px 24px rgba($tertiary-accent, 0.16);
      color: $blueish-black;
      padding: 8px;

      &-list {
        scrollbar-color: $blueish-black-24 $cool-gray-96; // overrides default css for mozilla firefox
        scrollbar-width: thin; // overrides default css for mozilla firefox

        &::-webkit-scrollbar-track {
          background-color: $cool-gray-96;
          border-radius: 3px;
        }

        &::-webkit-scrollbar {
          background-color: $cool-gray-96;
          border-radius: 3px;
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $blueish-black-24;
          border-radius: 3px;
        }
      }
    }

    .select-box__option {
      align-items: center;
      border-radius: 3px;
      display: flex;
      margin-bottom: 10px;

      &:hover {
        background-color: $blueish-black-8;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .rcl-select-box {
        &__label {
          &--hint {
            color: $disabled;
            line-height: $base-line-height;
            margin-top: 4px;
          }
        }
      }

      &--is-focused,
      &:active,
      &--is-selected {
        background-color: $blueish-black-16;
        color: $primary-text;
      }
    }

    &--highlighted {
      .select-box__option {
        &:first-child {
          border-radius: 0;
          position: relative;

          &::after {
            border-bottom: 1px solid $cool-gray-82;
            bottom: -8px;
            content: "";
            left: 52%;
            opacity: 0.5;
            position: absolute;
            transform: translateX(-50%);
            width: 96%;
          }
        }

        &:nth-child(2) {
          margin-top: 16px;
        }
      }
    }

    .select-box__value-container,
    .select-box__indicators {
      border: 0 solid $blueish-black-16;
      cursor: pointer;
    }

    .select-box__value-container {
      border-radius: 4px 0 0 4px;
      border-right: 0;
      padding: 0 15px;

      .select-box__placeholder {
        margin: 0;
      }
    }

    .select-box__value-container > div[class^="css-"] {
      padding-bottom: 0;
      padding-top: 0;
    }

    .select-box__indicators {
      border-left: 0;
      border-radius: 0 3px 3px 0;
    }

    &--notMulti {
      .select-box__value-container {
        padding: 0 15px;
      }
    }

    &--notMulti.select-box-container--tiny {
      .select-box__indicators,
      .select-box__value-container {
        height: 2rem;
      }
    }

    &--notMulti.select-box-container--small {
      .select-box__indicators,
      .select-box__value-container {
        height: 2.5rem;
      }
    }

    &--notMulti.select-box-container--large {
      .select-box__indicators,
      .select-box__value-container {
        height: 3rem;
      }
    }

    .select-box__value-container {
      div:last-of-type {
        margin: 0;
      }
    }

    .select-box__single-value {
      color: $blueish-black;
      margin: 0;
    }

    .select-box__multi-value {
      margin: 2px;
    }

    .select-box__multi-value__label {
      background-color: $athens-gray;
      color: $blueish-black;
      font-size: 12px;
    }

    .select-box__multi-value__remove {
      &,
      &:hover {
        background-color: $athens-gray;
        color: $primary-text;
      }
    }

    &--tiny {
      .select-box__control,
      .select-box__option {
        font-size: $rcl-font-14;
        min-height: 24px;
      }

      .select-box__indicators {
        min-height: 2rem;
      }

      .select-box__indicator {
        padding: 5px 8px;
      }

      .select-box__placeholder-text,
      .select-box__value {
        font-size: $rcl-font-14;
      }

      .select-box__value-container {
        min-height: 2rem;
      }
    }

    &--small {
      .select-box__control,
      .select-box__option {
        font-size: $rcl-font-14;
        min-height: 32px;
      }

      .select-box__option {
        color: $blueish-black;
      }

      .select-box__indicators {
        min-height: 2.5rem;
      }

      .select-box__placeholder-text,
      .select-box__value {
        font-size: $rcl-font-14;
      }

      .select-box__value-container {
        align-items: center;
        min-height: 2.5rem;
      }
    }

    &--large {
      .select-box__control,
      .select-box__option {
        font-size: $rcl-font-20;
        min-height: 40px;
      }

      .select-box__indicators {
        min-height: 3rem;
      }

      .select-box__placeholder-text,
      .select-box__value {
        font-size: $rcl-font-20;
      }

      .select-box__value-container {
        min-height: 3rem;
      }
    }

    &--error {
      .select-box__control {
        border: 1px solid $danger;
        border-radius: 3px;

        &:hover,
        &--is-focused,
        &--is-disabled {
          border-color: $danger;
        }
      }
    }
  }

  &__placeholder-text {
    color: $blueish-black-24;
    font-size: 1rem;
    white-space: nowrap;
  }

  &__pre-icon + &__placeholder-text {
    &--tiny {
      margin-left: 17px;
    }

    &--small {
      margin-left: 20px;
    }

    &--large {
      margin-left: 25px;
    }
  }

  &__pre-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;

    svg {
      fill: $santas-gray;
      height: 100%;
      width: 100%;
    }

    &--small,
    &--tiny {
      font-size: $rcl-font-14;
    }

    &--large {
      font-size: $rcl-font-20;
    }
  }
}
