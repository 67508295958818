@import "../../../styles/utils";

@mixin generate-modified-anchor($theme-color) {
  color: $theme-color;

  .icon svg {
    fill: $theme-color;
  }

  &:hover {
    color: darken($theme-color, 15%);
  }
}

.link {
  box-sizing: border-box;
  color: $primary-text;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  height: auto;
  line-height: 1.5;
  opacity: 1;
  position: relative;

  &,
  * {
    box-sizing: border-box;
  }

  &:hover .link__label-hint {
    opacity: 1;
    visibility: visible;
  }

  &:focus {
    outline: none;
  }

  &__label-hint {
    background-color: $secondary-accent;
    border-radius: 3px;
    color: $white;
    font-size: 12px;
    height: auto;
    line-height: 1.5;
    opacity: 0;
    padding: 0.1rem 0.6rem;
    position: absolute;
    transition: 0.3s 0.2s;
    visibility: hidden;
    white-space: nowrap;
    z-index: 4;

    &--bottom,
    &--top {
      left: 50%;
      transform: translateX(-50%);
    }

    &--bottom {
      top: calc(100% + 0.5rem);
    }

    &--top {
      bottom: calc(100% + 0.5rem);
    }

    &--right,
    &--left {
      top: 50%;
      transform: translateY(-50%);
    }

    &--right {
      left: calc(100% + 0.5rem);
    }

    &--left {
      right: calc(100% + 0.5rem);
    }

    &--tertiary {
      background-color: $tertiary-accent;
    }

    &--info {
      background-color: $info;
    }

    &--default {
      background-color: $leaky-rust;
    }

    &--success {
      background-color: $success;
    }

    &--warning {
      background-color: $warning;
    }

    &--danger {
      background-color: $danger;
    }
  }

  &--as-anchor {
    color: $primary-text;

    &:hover {
      color: darken($primary-text, 15%);
    }

    &.primary {
      @include generate-modified-anchor($primary-accent);
    }

    &.danger {
      @include generate-modified-anchor($danger);
    }

    &.success {
      @include generate-modified-anchor($success);
    }

    &.warning {
      @include generate-modified-anchor($warning);
    }

    &.tertiary {
      @include generate-modified-anchor($tertiary-accent);
    }

    &.info {
      @include generate-modified-anchor($info);
    }

    &.tiny {
      font-size: 0.875rem;
    }

    &.small {
      font-size: 1rem;
    }

    &.large {
      font-size: 1.25rem;
    }

    &.huge {
      font-size: 1.5rem;
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &--as-plain {
    font-size: $font-14;
  }
}
