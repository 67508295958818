@import "../../styles/utils";

.error_page {
  &--error-wrapper {
    height: 100%;
    background: url("../../assets/error_page.png") no-repeat center;
  }

  &--not_found-wrapper {
    background: url("../../assets/not_found_page.png") no-repeat center center / cover;
  }

  &--content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 100px;
  }

  &--title {
    font-size: $font-24;
    font-weight: $font-weight-700;
    margin-bottom: 15px;
  }

  &--button {
    font-weight: $font-weight-700;
    margin-top: 20px;
  }
}
