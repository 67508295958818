@import "../../../../../styles/utils";

.modal__content {
  background: $white;
  border-radius: 3px;
  display: flex;
  flex-grow: 1;
  font-size: $rcl-font-14;
  line-height: $base-line-height;
  max-height: 80vh;
  overflow: auto;

  @include viewport-is(mobile) {
    border-radius: 0;
    max-height: 100vh;
  }

  &--left {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }
}
