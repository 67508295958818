@import "../../../styles/utils";

.invoice {
  &-list {
    &__search-tab {
      column-gap: 10px;
      display: flex;
    }

    &__pagination {
      margin-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
    }

    &__search {
      width: 250px;

      .input__pre-icon {
        margin: 0 12px 0 5px;
      }
    }

    &__filter-button {
      column-gap: 10px;
      display: flex;
    }

    &__create-button {
      display: flex;
      justify-content: end;

      .button {
        font-weight: $font-weight-700;
      }
    }

    &__table {
      &-row {
        .emphasis-tag {
          margin: 0;
        }
      }
    }

    &-flat-file--user-info {
      align-items: center;
      column-gap: 10px;
      display: flex;
    }
  }

  &__flat-file {
    &--name-column {
      max-width: 400px;
    }

    &--name {
      align-items: center;
      display: flex;

      a {
        &::after {
          color: $blueish-black-16;
          content: "|";
          height: 16px;
          margin: 0 8px;
          width: 2px;
        }

        &:last-child {
          &::after {
            content: "";
          }
        }
      }

      .link {
        font-size: $font-14;
        font-weight: $font-weight-600;
      }
    }

    &--vertical-line {
      background-color: $blueish-black-16;
      height: 16px;
      margin: 0 8px;
      width: 2px;

      &__hide {
        display: none;
      }
    }
  }

  &__list {
    &--date {
      color: $blueish-black;
    }

    &--download {
      font-size: $font-14;
    }
  }

  &__user-rtabs {
    .table {
      .table__field--header {
        text-transform: capitalize;
      }

      .table__field-value {
        font-weight: $font-weight-400;
      }
    }

    .emphasis-tag__content__text {
      font-weight: $font-weight-600;
    }
  }
}
