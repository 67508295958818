@import "../../styles/utils";

.sort-list {
  &__wrapper {
    .table__field {
      font-weight: $font-weight-700;
      padding-right: 5px;
    }
  }

  &__icon {
    cursor: pointer;
    margin-left: 5px;

    .icon {
      &:last-of-type {
        position: relative;
        right: 2px;
      }
    }
  }
}
