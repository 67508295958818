.producer {
    &-wrapper {
        &--empty-placeholder {
            .empty-placeholder {
                align-items: center;
                display: flex;
                justify-content: center;
                min-height: 500px;
            }
        }
    }
}
