@import "../../../../../styles/utils.scss";

.farmland {
  &__checkbox {
    border: 1px solid $blueish-black-40;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px $blueish-black-8;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    padding: 10px;

    &--checked {
      border: 2px solid $blueish-black;
      margin-bottom: 0;
    }

    .organisation {
      &__form {
        &--radio__item {
          padding-bottom: 0;
        }
      }
    }
  }

  &__previous {
    &--select {
      margin-top: 5px;

      .select-box {
        &__label {
          font-weight: $font-weight-700;
        }
      }
    }
  }

  &__dgve {
    &--input {
      margin-top: 20px;

      .input {
        &__label {
          font-weight: $font-weight-700;
        }
      }
    }
  }

  &__main {
    &-title {
      font-weight: $font-weight-700;
    }

    &-description {
      font-size: $font-10;
      margin-top: 5px;
    }
  }

  &__form-description {
    font-size: $font-10;
    margin-top: 4px;
  }

  &__operation-form,
  &__previous,
  &__dgve {
    &--title {
      font-size: $font-14;
      font-weight: $font-weight-700;
      margin-top: 20px;
    }

    &--description {
      font-size: $font-10;
      margin-top: 5px;
    }
  }

  &__production-title {
    font-weight: $font-weight-700;
    margin: 5px 0;
  }

  &__warning {
    color: $yellow-orange;
    font-size: $font-10;
    margin-top: 10px;
  }
}
