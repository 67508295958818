@import "../../../../styles/utils";

.history {
  &__item {
    display: flex;
    font-weight: $font-weight-600;
    gap: 20px;
    margin-bottom: 5px;

    &--timeline {
      column-gap: 20px;
      display: flex;
      white-space: nowrap;

      span {
        margin-top: 1px;
      }

      &-icon-group {
        align-items: center;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
      }

      &-check {
        svg {
          fill: $pastal-green;
        }
      }

      &-vertical-line {
        background: $cool-gray-93;
        border-radius: 50px;
        height: 100%;
        width: 4px;
      }
    }

    &--info {
      width: 100%;

      &-title {
        font-weight: $font-weight-700;
        margin-bottom: 15px;
        margin-top: 1px;
      }

      &-description {
        background: $cool-gray-98;
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 10px 15px;

        > span:first-child {
          display: inline-block;
          font-weight: $font-weight-500;
          margin-bottom: 10px;
        }

        &-status {
          font-weight: $font-weight-500;
          margin-right: 10px;
        }

        &-user-role {
          font-weight: $font-weight-600;

          .emphasis-tag__content__text {
            font-size: $font-12;
          }
        }
      }

      &-description-no-margin {
        margin-bottom: 0;
      }
    }
  }
}
