@import "../../styles/utils";

.supplier-certificates {
  &__wrapper {
    .card {
      &--v2 {
        &__empty-card {
          align-items: center;
          justify-content: center;
          min-height: 533px;
        }
      }
    }
  }

  &__history-item {
    align-items: center;
    display: flex;
    font-size: $font-14;
    justify-content: flex-start;

    &--button {
      margin-left: 10px;
    }
  }

  &__initial {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &-text {
      font-size: $font-14;

      &--wrapper {
        align-items: center;
        display: flex;
      }

      &--header {
        font-weight: $font-weight-700;
      }

      &--description {
        font-weight: $font-weight-400;
      }
    }

    &-button {
      align-items: center;
      display: flex;

      &--text {
        color: $primary-text;
        font-size: $font-14;
        margin-right: 10px;
      }
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-actions {
      align-items: center;
      column-gap: 10px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;

      &-complain {
        .side-panel__header {
          margin-bottom: 0;
        }
      }
    }

    &-right {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      &-declaration-days {
        color: $disabled;
        font-size: 12px;
        margin-right: 10px;
      }
    }

    &-redeclare {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      column-gap: 20px;

      &-warning {
        color: $disabled;
        font-size: 12px;
        max-width: 390px;
      }
    }

    &-charta-period {
      align-items: baseline;
      display: flex;
    }

    .charta-validity {
      &__period {
        margin-bottom: 12px;
      }
    }
  }

  &__create {
    display: flex;
    margin-top: 20px;

    &--align-end {
      justify-content: flex-end;
    }

    &--align-between {
      justify-content: space-between;
    }
  }

  &__form {
    &-action {
      button {
        &:first-of-type {
          margin-right: 5px;
        }
      }
    }

    &-certificate-for {
      color: $disabled;
      font-size: 12px;
    }

    &-validity {
      font-weight: $boldest-font-weight;
      margin-top: 5px;
    }

    &--margin-top {
      margin-top: 20px;
    }
  }

  &__eligibility {
    color: $blueish-black;
    margin-bottom: 10px;
  }

  &__alert {
    background-color: $warning-medium;
    margin-bottom: 20px;
  }

  &__complain {
    display: flex;
    flex-flow: column;

    &-alert-header {
      font-weight: $boldest-font-weight;
      margin-bottom: 5px;
    }

    &-header {
      font-weight: $boldest-font-weight;
      margin-bottom: 20px;
    }

    &-form {
      .text-field-holder {
        height: 84px;
      }
    }
  }

  &__row {
    &-header {
      display: flex;
      flex-wrap: nowrap;

      &-complain {
        cursor: pointer;
        margin-left: 5px;
      }
    }

    &-actions {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &--download-icon {
      cursor: pointer;
      justify-content: center;
      opacity: 1;

      .icon {
        svg {
          fill: $dark-blue;
        }
      }
    }
  }
}
