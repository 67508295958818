@import "../../../../../styles/utils.scss";

.product-cultivation {
  &__outside-card {
    border: 1px solid $blueish-black-16;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 100%;

    &--empty {
      padding: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .icon__label {
      font-weight: $font-weight-700;
    }
  }

  &__ensure-transparency {
    align-items: flex-start;
    display: flex;

    &--card {
      padding: 10px;
    }

    &--title {
      font-weight: $font-weight-700;
    }

    &--text {
      bottom: 4px;
      position: relative;
    }

    .icon {
      margin-right: 10px;
    }
  }

  &__empty {
    &-header {
      font-size: $font-14;
      font-weight: $font-weight-700;
    }

    &-description {
      border: 1px solid $blueish-black-16;
      border-radius: 4px;
      color: $blueish-black-40;
      font-size: $font-14;
      font-weight: $font-weight-400;
      margin-top: 10px;
      padding: 20px;
    }
  }

  &__input-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0;

    &:last-of-type {
      padding-bottom: 10px;
    }

    .input {
      display: flex;

      &__label {
        margin-right: 10px;
      }
    }
  }

  &__other-goat {
    &--wrapper {
      padding: 10px;
    }
  }

  &__milk-info {
    color: $info;

    &--cow {
      display: block;
      margin-top: 15px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: $font-weight-700;
  }

  &__produce-type {
    font-size: $font-16;
    margin-bottom: 5px;
  }

  &__paragraph {
    padding: 0 20px;
  }

  &__header {
    font-size: 16px;
    font-weight: $font-weight-700;
  }

  &__description {
    font-size: $font-10;
    font-weight: $font-weight-400;
    padding-top: 5px;
  }

  &__wrapper {
    padding-top: 20px;
    width: 50%;
  }

  &__card {
    border: 1px solid $blueish-black-16;
    border-radius: 4px;
    margin-top: 20px;
    padding: 0;
    width: 100% !important;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }

  &__inside-card {
    border: 1px solid $blueish-black-16;
    margin: 20px;
    padding: 0;
  }

  &__details-text {
    font-size: $font-16;
    font-weight: $font-weight-700;
    margin-bottom: 20px;
  }

  &__poultry {
    border: 1px solid $blueish-black-16;
    margin: 20px 0;
    margin-left: 20px;
    width: 94%;
  }

  &__other-text {
    font-weight: $font-weight-700;
  }

  &__input {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 0;

    &:last-of-type {
      padding-bottom: 10px;
    }
  }

  &__gardening-item {
    display: inline-block;
    margin-bottom: 20px;
    padding-left: 10px;
    vertical-align: middle;
    width: 50%;

    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 10px;
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      margin-bottom: 10px;
    }
  }

  &__milk-member {
    align-items: center;
    display: flex;
    padding: 10px;

    &--options {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: space-evenly;

      .organisation {
        &__form {
          &--radio {
            &__item {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .farmland {
      &__checkbox {
        border: none;
        box-shadow: none;
      }
    }
  }

  &__milk-select {
    align-items: center;
    display: flex;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-top: 0;

    .select-box {
      &-wrapper {
        width: 45%;
      }
    }
  }

  &__dwarf-goats {
    margin-top: 10px;
  }
}
