@import "../../../styles/utils";

.rcl-calendar {
  .DayPicker {
    border: 1px solid $blueish-black-16;
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px $blueish-black-16;

    color: $big-stone;

    .DayPickerNavigation {
      &_button {
        align-items: center;
        border: none;
        display: inline-flex;
        height: 32px;
        justify-content: center;
        left: 22px;
        padding: 3px;
        position: absolute;
        top: 18px;
        width: 24px;

        &:first-of-type {
          left: 20px;
        }

        &:last-of-type {
          transform: translate(250px, 0);
        }

        &:hover {
          background-color: rgba($blueish-black, 0.16);
          border-radius: 3px;
        }
      }
    }

    .CalendarDay {
      border: 1px solid rgba($blueish-black, 0.16);

      &:hover {
        background: rgba($blueish-black, 0.16);
        border: 1px solid rgba($blueish-black, 0.24);
      }

      &__selected {
        background: $blueish-black;
        border: 1px solid rgba($blueish-black, 0.16);
        font-weight: $font-weight-700;

        &:hover {
          background: $blueish-black;
          border: 1px solid rgba($blueish-black, 0.16);
        }
      }

      &__selected_span,
      &__hovered_span {
        background: rgba($blueish-black, 0.16);
        border: 1px solid rgba($blueish-black, 0.24);
        color: $blueish-black;
      }
    }

    .CalendarMonth_caption {
      color: $blueish-black;
      font-weight: $boldest-font-weight;
    }

    .DayPickerKeyboardShortcuts_buttonReset {
      outline: none;

      &::before {
        border-right: 33px solid $blueish-black;
      }
    }
  }

  &__has-filters {
    .DayPicker {
      &_weekHeader {
        top: 104px;
      }

      .DayPickerNavigation {
        z-index: 3;

        &_button {
          top: 66px;

          &:first-of-type {
            left: 16px;
          }
        }
      }

      .rcl-calendar__dropdown-navigation-select-box {
        width: 132px;
      }

      .CalendarMonthGrid {
        background: transparent;
        z-index: 2;
      }

      .CalendarMonth {
        background: transparent;
      }
    }

    .select-box__single-value {
      display: flex;
      justify-content: flex-start;
    }

    .select-box__value-container {
      padding: 0 12px;
    }
  }

  &__dropdown-navigation {
    column-gap: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 16px;

    &__selectbox {
      column-gap: 10px;
      display: flex;
      justify-content: space-between;
    }

    &-select-box {
      width: 120px;

      &:first-of-type .select-box__value-container {
        padding-left: 12px;
      }

      &:last-of-type {
        width: 88px;
      }

      .select-box__menu-list {
        max-height: 200px;
      }
    }
  }
}
