@import "../../../styles/utils";

.radio {
  align-items: center;
  border-color: $blueish-black;
  color: $blueish-black;
  display: inline-flex;
  font-size: 1rem;
  margin: 0 1rem 0 0;

  &,
  * {
    box-sizing: border-box;
  }

  &__input {
    display: none;
  }

  &-circle {
    align-items: center;
    appearance: none;
    border: 2.5px solid $blueish-black;
    border-radius: 50%;
    cursor: pointer;
    height: calc(1.125rem + 0.25rem);
    justify-content: center;
    position: relative;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    width: calc(1.125rem + 0.25rem);

    &::before,
    &::after {
      background-color: $blueish-black;
      border-radius: 50%;
      content: "";
      display: block;
      height: 60%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.2s ease;
      width: 60%;
    }

    &::after {
      background-color: $blueish-black-16;
      transition: none;
    }

    &:hover {
      &::after {
        background-color: $blueish-black-24;
        transform: translate(-50%, -50%) scale(1.5);
      }
    }

    &-tooltip {
      padding: 4px 7px;
      &.rcl-tooltip.rcl-popover {
        padding: 4px 7px;
      }

      &--small {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 12px;
          }
        }
      }

      &--medium {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 16px;
          }
        }
      }

      &--large {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 14px;
          }
        }
      }

      &--huge {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 18px;
          }
        }
      }
    }

    &--checked {
      border: 2.5px solid $blueish-black;

      &::before {
        transform: translate(-50%, -50%) scale(1.2);
      }

      &::after {
        background-color: $blueish-black-16;
      }
    }
  }

  &__label {
    margin-left: 6px;
  }

  &--small {
    font-size: 0.75rem;

    .radio__label {
      margin-left: 6px;
    }

    .radio-circle {
      @include size(12px, 12px);

      &::after {
        @include size(4px, 4px);
      }
    }
  }

  &--medium {
    font-size: 0.875rem;

    .radio__label {
      margin-left: 10px;
    }

    .radio-circle {
      @include size(16px, 16px);

      &::after {
        @include size(5px, 5px);
      }
    }
  }

  &--large {
    font-size: 1.125rem;

    .radio__label {
      margin-left: 8px;
    }

    .radio-circle {
      @include size(14px, 14px);

      &::after {
        @include size(5px, 5px);
      }
    }
  }

  &--huge {
    font-size: 1.375rem;

    .radio__label {
      margin-left: 10px;
    }

    .radio-circle {
      @include size(16px, 16px);

      &::after {
        @include size(6px, 6px);
      }
    }
  }

  &--disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
