@import "../../../styles/utils";

.choose-file {
  color: $blueish-black;
  display: flex;
  flex-direction: column;
  font-size: $font-14;
  font-weight: $font-weight-400;
  row-gap: 6px;

  &__input {
    align-items: center;
    column-gap: 10px;
    display: flex;
  }

  &__button {
    border: 1px solid $blueish-black-16;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba($blueish-black, 0.16);
    color: $blueish-black;

    .icon {
      svg {
        height: 21px !important;
        width: 16px !important;
      }
    }
  }

  &__contract {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-name {
      align-items: center;
      display: flex;
    }

    &-filename {
      font-size: $font-14;
    }

    &-vertical {
      &--line {
        &__left {
          background-color: $blueish-black-16;
          height: 20px;
          margin-right: 20px;
          width: 2px;
        }

        &__right {
          background-color: $blueish-black-16;
          height: 20px;
          margin: 0 10px;
          width: 2px;
        }
      }
    }

    &-footer {
      align-items: center;
      display: flex;
      font-weight: $font-weight-700;
    }

    &-delete {
      &--text {
        margin-left: 10px;
      }
    }
  }
}
