@import "../../../styles/utils";

.icon-button {
  @include reset-all-defaults;
  align-items: center;
  background: transparent;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  position: relative;
  width: 2rem;

  &:active {
    background: transparent;
  }

  @mixin icon-button-colors($color, $color-in-hover, $background-color) {
    .icon-button {
      &__icon svg {
        fill: $color;
      }
    }

    &:hover {
      background: $background-color;

      .icon-button {
        &__label {
          background: $color-in-hover;
          height: auto;
          opacity: 1;
          visibility: visible;
        }

        &__icon svg {
          fill: $color-in-hover;
        }
      }
    }

    &:focus {
      outline: none;
    }

    &:active {
      background: $background-color;

      .icon-button__icon svg {
        fill: $color;
      }
    }
  }

  &--default {
    @include icon-button-colors($blueish-black, $blueish-black, $blueish-black-16);
  }

  &--tertiary {
    @include icon-button-colors($dark-blue, $dark-blue, rgba($dark-blue, 0.16));
  }

  &--success {
    @include icon-button-colors($green, $success, $success-light);
  }

  &--warning {
    @include icon-button-colors($warning, $warning, $warning-light);
  }

  &--danger {
    @include icon-button-colors($normal-red, $normal-red, rgba($normal-red, 0.24));
  }

  &--primary-text {
    @include icon-button-colors($primary-text, $primary-text, $primary-text-light);
  }

  &--warning-dark {
    @include icon-button-colors($warning-dark, $warning-dark, $warning-dark-16);
  }

  &--success-dark {
    @include icon-button-colors($success-dark, $success-dark, $success-dark-8);
  }

  &--danger-dark {
    @include icon-button-colors($danger-dark, $danger-dark, $danger-dark-8);
  }

  &--white {
    @include icon-button-colors($white, $white, $white-16);

    .icon-button__label {
      background: $white;
      color: $black;
    }
  }

  &--icon-only {
    background: transparent;
    border: none;

    &:hover {
      background: transparent;
    }

    &:active {
      background: transparent;
      border: none;

      .icon-button__icon {
        fill: $cool-gray-30;

        svg {
          fill: $cool-gray-30;
        }
      }
    }
  }

  .icon-button__badge {
    height: 8px;
    position: absolute;
    right: 9%;
    top: 9%;
    width: 8px;
  }

  &--xxs {
    @include size(1.5rem, 1.5rem);

    .icon-button {
      &__icon {
        font-size: 0.625rem;
      }

      &__badge {
        right: 7%;
        top: 7%;
      }
    }
  }

  &--xs {
    @include size(1.75rem, 1.75rem);

    .icon-button {
      &__icon {
        font-size: 0.75rem;
      }

      &__badge {
        right: 8%;
        top: 8%;
      }
    }
  }

  &--tiny {
    @include size(2rem, 2rem);

    .icon-button {
      &__icon {
        font-size: 0.875rem;
      }

      &__badge {
        right: 9%;
        top: 9%;
      }
    }
  }

  &--small {
    @include size(2.5rem, 2.5rem);

    .icon-button {
      &__icon {
        font-size: 1rem;
      }

      &__badge {
        right: 10%;
        top: 10%;
      }
    }
  }

  &--large {
    @include size(3rem, 3rem);

    .icon-button {
      &__icon {
        font-size: 1.25rem;
      }

      &__badge {
        right: 11%;
        top: 11%;
      }
    }
  }

  &--huge {
    @include size(3.5rem, 3.5rem);

    .icon-button {
      &__icon {
        font-size: 1.5rem;
      }

      &__badge {
        right: 12%;
        top: 12%;
      }
    }
  }

  &--padding {
    &-around {
      padding: 8px;
    }

    &-none {
      padding: 0;
    }

    &-top {
      padding-top: 8px;
    }

    &-right {
      padding-right: 8px;
    }

    &-bottom {
      padding-bottom: 8px;
    }

    &-left {
      padding-left: 8px;
    }
  }

  &__label {
    background: $secondary-accent;
    border-radius: 0.3rem;
    color: $white;
    font-size: 12px;
    left: 50%;
    line-height: 1.5em;
    opacity: 0;
    padding: 0.1rem 0.6rem;
    position: absolute;
    top: calc(100% + 0.5rem);
    transform: translateX(-50%);
    transition: 0.3s 0.2s;
    visibility: hidden;
    white-space: nowrap;
    z-index: 4;

    &--right,
    &--left {
      left: auto;
      top: 50%;
      transform: translate(0, -50%);
    }

    &--right {
      left: calc(100% + 0.5rem);
    }

    &--left {
      right: calc(100% + 0.5rem);
    }

    &--top {
      bottom: calc(100% + 0.5rem);
      top: auto;
    }
  }

  &__icon {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    justify-content: center;

    svg {
      fill: $cool-gray-30;
    }
  }

  // all the inverted states
  &-dark {
    background: $cool-gray-24;
    border-color: $cool-gray-18;

    .icon-button__label {
      background: $cool-gray-90;
      color: $black;
    }

    .icon-button__icon {
      svg {
        fill: $cool-gray-80;
      }
    }

    &:hover {
      background: $cool-gray-18;
      border-color: $cool-gray-18;
    }

    &:focus {
      outline: none;
    }

    &:active {
      background: $cool-gray-95;
      border: 0.1rem solid $cool-gray-95;

      .icon-button__icon {
        fill: $cool-gray-30;
      }
    }
  }

  &--action {
    background: rgba($attracting-peach, 0.15);
    border: 0.1rem solid rgba($attracting-peach, 0.3);

    .icon-button__icon {
      svg {
        fill: $attracting-peach;
      }
    }

    &:hover {
      background: rgba($attracting-peach, 0.3);
      border: 0.1rem solid rgba($attracting-peach, 0.3);

      .icon-button__icon {
        fill: $attracting-peach;
      }
    }

    &:focus {
      outline: none;
    }

    &:active {
      background: $attracting-peach;
      border: 0.1rem solid $attracting-peach;

      .icon-button__icon {
        fill: $white;
      }
    }
  }

  &--tertiary-inverse {
    background: $blueish-black;

    .icon-button__icon {
      svg {
        fill: rgba($blueish-black, 0.3);
      }
    }

    &:hover {
      background: $blueish-black;

      .icon-button__icon svg {
        fill: $blueish-black;
      }
    }

    &:focus {
      outline: none;
    }

    &:active {
      background: $blueish-black;

      .icon-button__icon {
        fill: $blueish-black;
      }
    }
  }

  &--standalone {
    background: none;
    border: 0.1rem solid transparent;

    &.icon-button {
      &--action {
        .icon-button__icon {
          fill: $attracting-peach;
        }
      }

      &:active {
        background: $black-28;
        .icon-button__icon {
          fill: $white;
        }
      }
      &:hover {
        background: $black-14;
      }
    }

    .icon-button__icon {
      fill: $cool-gray-60;
    }
  }

  &--disabled {
    border: 0.1rem solid transparent;
    opacity: 0.4;
    pointer-events: none;
  }
}

// Fix for IE
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .icon-button__icon svg {
    @include size(4rem, 4rem);
  }

  .cart-item__remove-button .icon-button__icon {
    height: 2rem;
  }

  .icon-button--action .icon-button__icon {
    @include size(3rem, 3rem);
  }
}
