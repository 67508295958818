@import "../../../../../styles/utils";

.modal__feedback {
  @include size(100%, 100%);
  align-items: center;
  background: $white;
  bottom: 0;
  display: flex;
  position: absolute;
  right: 0;

  &--left {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }
}
