@import "../../../styles/utils";

.table-loader-placeholder {
  background: transparent;
  border-top: 20px solid transparent;
  position: relative;

  &::after {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 1px 12px $grey-shade;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  &:last-child {
    &::after {
      height: calc(100% + 10px);
    }
  }

  .table__field {
    border: none;
  }

  .content-loader {
    padding: 10px 0;
  }
}

.empty-content-placeholder {
  width: 100%;
  word-spacing: normal;

  &--top-gap {
    margin-top: 20px;
  }

  &--with-card {
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 1px 12px rgba($black, 0.08);
    display: flex;
    justify-content: center;
    min-height: 500px;
  }

  &--no-card {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 500px;
    padding: 20px;

    .empty-card {
      &__icon {
        align-items: center;
        align-self: center;
        background: rgba($blueish-black, 0.16);
        border-radius: 50%;
        display: flex;
        height: 70px;
        justify-content: center;
        margin: 0 auto;
        width: 70px;

        svg {
          fill: rgba($blueish-black, 0.24);
          font-size: 32px;
          height: 32px;
          margin: 0 auto;
          width: 32px;
        }
      }

      &__text {
        align-self: center;
        color: rgba($blueish-black, 0.4);
        font-size: 16px;
        font-style: normal;
        font-weight: $font-weight-700;
        letter-spacing: -0.02em;
        line-height: 22px;
        margin: 0 0 auto;
        padding: 20px 0 0;
        text-align: center;
      }
    }
  }
}

.row-loader-placeholder {
  margin-bottom: 20px;
}
