@import "../../styles/utils";

.login {
  &__button {
    border-radius: 4px;
    margin-top: 46px;
    width: 250px;
  }

  &__form {
    &-email {
      margin-top: 30px;
      width: 250px;
    }

    &-password {
      margin-top: 45px;
      width: 250px;

      &--label {
        display: flex;
        justify-content: space-between;
        width: 250px;

        &__forgot-password {
          color: $normal-red;
          cursor: pointer;
        }
      }
    }

    &-error {
      .rcl-alert {
        padding: 10px;
        min-width: 250px;

        &-container {
          justify-content: center;
        }
      }
    }
  }

  &__verify-otp {
    align-items: center;
    display: flex;
    flex-direction: column;

    &-label {
      margin-top: 30px;
      text-align: center;
      width: 357px;
    }
  }
}
