@import "../../../styles/utils";

.card {
  background-color: $white;
  border: 1px solid $cool-gray-90;
  border-radius: 4px;
  color: $blueish-black;

  @include viewport-is(tab-mobile) {
    border-radius: 0;
  }

  &--normal-shadow {
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.08);
  }

  &--dark-shadow {
    box-shadow: 0 3px 10px rgba($tertiary-accent, 0.16);
  }

  &--v2 {
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px;

    &__empty {
      &-card {
        display: flex;
        flex-flow: column wrap;
      }

      &-wrapper {
        padding: 20px;
      }

      &-title {
        font-size: 14px;
      }

      &-image {
        align-items: center;
        align-self: center;
        background: rgba($blueish-black, 0.16);
        border-radius: 50%;
        display: flex;
        height: 70px;
        justify-content: center;
        margin: 0 auto;
        width: 70px;

        svg {
          fill: rgba($blueish-black, 0.24);
          font-size: 32px;
          height: 32px;
          margin: 0 auto;
          width: 32px;
        }

        &--tertiary {
          background: rgba($blueish-black, 0.16);

          svg {
            fill: rgba($blueish-black, 0.24);
          }
        }

        &--success {
          background: $blueish-black-16;

          svg {
            fill: $blueish-black-24;
          }
        }
      }

      &-text {
        align-self: center;
        color: rgba($blueish-black, 0.4);
        font-size: 16px;
        font-style: normal;
        font-weight: $font-weight-700;
        letter-spacing: -0.02em;
        line-height: 22px;
        margin: 0 0 auto;
        padding: 20px 0 0;
        text-align: center;
      }
    }

    &__image {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      margin-bottom: 20px;
      max-height: 200px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }

    .card {
      &__header {
        border: none;

        &-text {
          font-size: 14px;
          font-weight: $bold-font-weight;
          padding: 0;
        }

        &--title {
          margin-bottom: 10px;
          min-height: auto;
        }
      }

      &__body-section {
        font-size: 14px;
        font-weight: $bolder-font-weight;
        line-height: 1.5;
      }
    }
  }

  &--normal {
    width: 400px;
  }

  &--full {
    width: 100%;
  }

  &--dark {
    background-color: $cool-gray-30;
    border: 1px solid $cool-gray-20;
    color: $cool-gray-90;
  }

  &--transparent {
    background-color: rgba($white, 0.9);
    border: 1px solid rgba($cool-gray-90, 0.9);
  }

  &--dark-transparent {
    background-color: rgba($cool-gray-30, 0.9);
    border: 1px solid rgba($cool-gray-20, 0.9);
    color: $cool-gray-90;
  }

  &__footer {
    color: $tertiary-accent;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;

    &--dark {
      color: $cool-gray-90;
    }

    svg {
      fill: $tertiary-accent;
      font-size: 14px;
    }

    &-expand-indicator {
      align-items: center;
      cursor: pointer;
      display: flex;

      .icon {
        margin-left: 8px;
      }
    }
  }

  &__header {
    align-items: center;
    border-bottom: 1px solid $cool-gray-90;
    display: flex;
    justify-content: space-between;

    &--dark {
      border-bottom: 1px solid $cool-gray-20;
    }

    &--dark-transparent {
      border-bottom: 1px solid rgba($cool-gray-20, 0.9);
    }

    &--transparent {
      border-bottom: 1px solid rgba($cool-gray-90, 0.9);
    }

    &--title {
      min-height: 46px;
    }

    &--title-center {
      justify-content: center;
    }

    &-text {
      font-size: 18px;
      font-weight: 500;
      padding-left: 15px;

      &--center {
        padding-left: 0;
      }
    }

    &--collapsible {
      cursor: pointer;
    }

    &-actions {
      margin-left: 20px;
      min-height: 0;

      .icon svg {
        transition: transform 0.3s ease;
      }
    }
  }

  &__body {
    padding-left: 8px;
    padding-right: 8px;

    &--normal {
      max-height: 130px;
      overflow: hidden;
    }

    &--small {
      max-height: 45px;
      overflow: hidden;
    }

    &--medium {
      max-height: 85px;
      overflow: hidden;
    }

    &--large {
      max-height: 150px;
      overflow: hidden;
    }
  }
}
