@import "../../styles/utils";

.message {
  &__wrapper {
    margin-right: 20px;
  }

  &__popover {
    font-size: 14px;
    font-weight: 400;

    &-wrapper {
      .rcl-popover {
        &__content {
          padding: 0;
        }
      }
    }

    &-content {
      padding: 12px 4px;

      &:hover {
        background-color: rgba($green, 0.08);
        cursor: pointer;
      }
    }
  }

  &__tab {
    padding-left: 20px;
  }

  &__create-search {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__create {
    &-card {
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      max-width: 640px;
      padding: 20px;

      &__container {
        padding: 20px;
      }
    }

    &--title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .icon-button {
        margin-right: 0;
        padding-right: 0;
      }
    }

    &--form {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &__field {
        margin-bottom: 20px;
      }

      &__header {
        color: $blueish-black;
        font-size: 20px;
        font-weight: $font-weight-700;
        line-height: 27px;
        margin: 0;
        margin-bottom: 20px;
      }

      &__checkbox {
        display: flex;
        flex-direction: row;

        &--label {
          margin-bottom: 14px;
        }

        .rcl-checkbox:not(:first-of-type) {
          margin-left: 20px;
        }
      }

      &__confirmation {
        display: flex;
      }

      &__field-error {
        color: $normal-red;
        font-size: 12px;
        margin-left: 5px;
        margin-top: 5px;
        text-transform: capitalize;
      }
    }

    &--modal {
      .rcl-modal {
        &__container {
          max-width: 520px;
        }

        &__content {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &--footer {
      display: flex;
      justify-content: flex-start;

      &__cancel-btn {
        margin-left: 10px;
      }
    }

    &--horizontal-row {
      margin-top: 20px;
      opacity: 0.3;
    }
  }
}
