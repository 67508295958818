@import "../../../styles/utils";

.goods {
  &__container {
    column-gap: 15px;
  }

  &__subcontainer {
    .card {
      height: 100%;
    }
  }

  &__remaining-time {
    font-size: $font-12;

    &--day {
      font-size: $font-20;
      font-weight: $font-weight-200;
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .organisation {
      &__bio-cuisine-status {
        align-items: center;
      }
    }
  }

  &-period {
    display: flex;
    justify-content: space-between;

    &__container {
      &-current {
        align-items: center;
        display: flex;
        font-size: 14px;
        margin-top: 5px;
        white-space: pre;

        &--date {
          font-weight: $font-weight-700;
        }

        .supplier-certificates__row-header-complain {
          font-weight: $font-weight-700;
        }
      }
    }
  }

  &__deadline {
    font-size: $font-14;
    margin-top: 5px;

    &--date {
      font-weight: $font-weight-700;
    }
  }

  &-form {
    &__select {
      &-month {
        font-size: $font-12;
        font-weight: $font-weight-200;
      }
      &-good {
        font-size: $font-12;
        font-weight: $font-weight-700;
        margin-top: 5px;
      }
    }

    &__month-picker {
      margin-top: 20px;
    }

    &__buttons {
      margin-top: 20px;

      &-cancel {
        margin-left: 5px;
      }
    }

    &--with-top-margin {
      margin-top: 20px;
    }
  }

  &__card {
    &-edit-wrapper {
      display: flex;
      font-weight: 700;
      justify-content: space-between;
    }

    &-bio-certified {
      font-weight: $font-weight-700;
      margin-top: 20px;

      &--amount {
        font-size: $font-20;
        font-weight: $font-weight-400;
      }
    }
  }

  &__progressbar {
    display: flex;

    .organisation {
      &__bio-cuisine-status {
        margin: 0 10px;

        .icon {
          svg {
            fill: $green;
          }
        }
      }
    }

    &--text {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      justify-content: space-between;
    }

    &--wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 8px;
    }

    &--percentage {
      font-size: 12px;
      text-align: right;
    }
  }

  &__average {
    &-title {
      font-size: $font-16;
      font-weight: $font-weight-700;
    }

    &-header {
      font-size: $font-12;
      font-weight: $font-weight-400;
      margin-top: 20px;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-certified,
    &-buds {
      font-size: $font-12;
      font-weight: $font-weight-700;

      &--percentage {
        font-size: $font-20;
        font-weight: $font-weight-200;
        margin-left: 5px;
      }
    }

    &-suggestion {
      &--wrapper {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
      }

      &--star {
        font-size: $font-12;
        font-weight: $font-weight-700;
        margin-right: 10px;

        &--percentage {
          margin-right: 10px;

          &__disabled {
            opacity: 0.3;
          }
        }
      }

      &--text {
        font-size: $font-12;
        margin-top: 25px;
      }

      &--header {
        display: flex;
        flex-direction: column;
        font-size: $font-12;
        font-weight: $font-weight-700;
        justify-content: space-around;
        min-height: 52px;
      }

      &--body {
        font-size: $font-20;
        font-weight: $font-weight-200;
      }
    }
  }

  &__last-card {
    min-height: 285px;
  }

  &__modal {
    .modal {
      &__container {
        max-width: 396px;
      }

      &__content {
        justify-content: center;
        margin-top: 10px;
        text-align: center;
        white-space: pre-wrap;
      }

      &__title {
        align-items: center;
        flex-flow: column;
        justify-content: center;
      }
    }

    &--title {
      font-size: 16px;
      font-weight: $font-weight-700;
      line-height: 24px;
      margin: 14px 0;
    }

    &--content {
      font-size: 14px;
      font-weight: $font-weight-400;
      line-height: 21px;
    }

    &--stars .icon svg {
      fill: $green;
    }

    &--confetti {
      width: 50px;
    }
  }

  &__action {
    display: flex;

    &-complain {
      margin-right: 8px;
    }

    &-change-month {
      margin-right: 10px;
    }
  }

  &__expired-list {
    &-item {
      padding: 20px 0;

      &:first-of-type {
        padding-top: 0;
      }

      &-wrapper {
        margin-top: 20px;
      }

      &-summary {
        &-wrapper {
          padding-top: 20px;

          &-title {
            font-weight: $font-weight-700;
          }

          &-content {
            font-size: 20px;
          }
        }
      }

      &-declaration {
        font-size: 14px;
        padding-top: 5px;
      }

      &-duration {
        font-size: 14px;
        font-weight: $font-weight-700;
      }

      &-declaration-date {
        font-weight: $font-weight-700;
      }

      &-percentage {
        display: flex;
        justify-content: space-between;

        &-current {
          font-weight: $font-weight-400;
        }

        &-difference {
          align-items: center;
          display: flex;
          font-size: 14px;

          .icon {
            padding-left: 5px;
          }

          &--increased {
            color: $pastal-green;
          }

          &--decreased {
            color: $normal-red;
          }
        }
      }
    }

    .empty-placeholder {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 500px;
    }
  }

  .card {
    &__body {
      &-section {
        font-weight: $font-weight-400;
      }
    }
  }

  .progress-bar {
    margin-bottom: 1px;
  }
}
