@import "../../../styles/utils.scss";

.side-panel {
  background-color: $white;
  box-sizing: border-box;
  color: $blueish-black;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 400px;
  padding: 10px 5px 20px 20px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: 20%;
  z-index: 20;

  @include viewport-is(tab-mobile) {
    width: 40%;
  }

  @include viewport-is(mobile) {
    width: 100%;
  }

  &__backdrop {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: background-color 300ms ease-in-out, left 300ms ease-in-out;
    width: 100%;
    z-index: 20;

    &--visible {
      background-color: rgba($primary-text, 0.5);
      pointer-events: initial;
    }
  }

  &--open {
    box-shadow: 0 -3px 12px rgba($blueish-black, 0.24);
    transform: initial;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    letter-spacing: normal;

    .side-panel {
      &__close-btn {
        margin-left: auto;

        .icon {
          svg {
            fill: $primary-text;
          }
        }
      }
    }
  }

  .icon-button {
    &__icon {
      font-size: 20px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: $font-weight-700;
    flex-grow: 0;
    letter-spacing: normal;
    word-spacing: normal;
    display: inline-block;
    text-align: left;
    text-align-last: left;
  }

  &__transform {
    transition: transform 300ms ease-in-out;
  }

  &__body {
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 10px;
    scrollbar-color: $blueish-black $blueish-black;
    scrollbar-width: thin;

    &::-webkit-scrollbar-track {
      background-color: $subordinate-snow;
      border-radius: 3px;
    }

    &::-webkit-scrollbar {
      background-color: $subordinate-snow;
      border-radius: 3px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $blueish-black;
      border-radius: 3px;
    }
  }
}
