@import "../../../../../styles/utils";

.modal {
  &__title {
    display: flex;
    flex-grow: 1;
    font-size: $rcl-font-20;
    font-weight: $bold-font-weight;

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  .icon {
    cursor: pointer;
  }
}
