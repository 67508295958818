@import "../../../styles/utils";

.side-nav {
  background-color: $blueish-black;
  box-sizing: border-box;
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 12px;
  position: absolute;
  transition: width 230ms linear, box-shadow 230ms linear;
  width: 65px;
  z-index: 10;

  * {
    box-sizing: border-box;
  }

  &__brand,
  &__footer-brand {
    display: flex;
    justify-content: center;
    position: relative;

    > * {
      display: block;
    }

    &-icon {
      opacity: 1;
      position: absolute;
      transition: opacity 1ms linear 230ms;
    }

    &-logo {
      margin: auto;
      opacity: 0;
      overflow: hidden;
      transition: opacity 50ms linear 230ms;
    }
  }

  &__brand {
    &-img {
      width: 36px;
    }

    &-title-img {
      width: 120px;
    }
  }

  &__footer-brand {
    flex-wrap: wrap;
    margin: 14px -12px;
    padding: 10px;
    transition: padding 1ms linear 80ms;

    &-img {
      width: 26px;
    }

    &-title-img {
      width: 167px;
    }

    &-slogan {
      align-self: flex-end;
      color: $white;
      font-size: 12px;
      font-weight: $font-weight-400;
      margin-top: 8px;
      opacity: 0;
      text-align: center;
      text-decoration: none;
      transition: opacity 180ms linear;
      white-space: nowrap;
      width: 100%;
    }
  }

  &__button {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 3px;
    color: $white;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    margin: 22px 0 0;
    padding: 10px;
    text-decoration: none;
    transition: background-color 180ms linear;
    width: 100%;

    .button__icon {
      font-size: 21px;
      margin: 0;
    }

    &-label {
      margin-left: 0;
      overflow: hidden;
      transition: margin-left linear 200ms;
      white-space: nowrap;
    }

    &:hover {
      background-color: $wedgewood;
      border: none;
      color: $white;
      padding: 10px;
    }
  }

  &__list,
  .side-nav__footer-list {
    display: block;
    list-style: none;
    margin-block-end: 0;
    margin-block-start: 0;
    margin-top: 22px;
    padding-inline-start: 0;

    &-item {
      align-items: center;
      color: $white;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      margin: 14px -12px;
      padding: 10px 10px 10px 20px;
      position: relative;
      text-decoration: none;

      &-icon {
        display: block;
        font-size: 18px;
        padding: 0 4px;

        svg {
          fill: $lynch;
          transition: fill 180ms linear;
        }
      }

      &-label {
        display: inline-flex;
        font-size: 14px;
        margin-left: 12px;
        overflow: hidden;
        white-space: nowrap;
      }

      &-badge {
        &.badge {
          &--small {
            background-color: $primary-accent;
            position: absolute;
          }
        }

        &--dot.badge {
          &--small {
            height: 8px;
            left: 40px;
            top: 4px;
            transform: scale(1);
            transition: transform 100ms ease-in-out;
            width: 8px;
          }
        }

        &--with-number.badge {
          &--small {
            border-radius: 50px;
            height: auto;
            margin-left: auto;
            padding: 2px 7px;
            right: 24px;
            transform: scale(0);
            transition: transform 100ms ease-in-out;
            width: auto;
          }
        }
      }

      &:hover,
      &--active {
        .side-nav__list-item-icon svg,
        .side-nav__footer-list-item-icon svg {
          fill: $white;
        }
      }

      &--active {
        font-weight: 700;
      }
    }
  }

  &__list {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: $subordinate-snow $considerate-royal;
    scrollbar-width: thin;
    width: calc(100% + 8px);

    &-item {
      width: calc(100% + 6px);
    }

    &::-webkit-scrollbar-track {
      background-color: $considerate-royal;
      border-radius: 3px;
    }

    &::-webkit-scrollbar {
      background-color: $considerate-royal;
      border-radius: 3px;
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $subordinate-snow;
      border-radius: 3px;
    }
  }

  &__footer-list {
    width: 100%;
  }

  &:hover {
    box-shadow: 6px 0 6px rgba($secondary-accent, 0.3);
    width: 280px;

    .side-nav__brand,
    .side-nav__footer-brand {
      &-icon {
        opacity: 0;
        transition: opacity 1ms linear;
      }

      &-logo {
        opacity: 1;
        transition: opacity 1ms linear;
        width: auto;
      }
    }

    .side-nav__button {
      &-label {
        display: inline-block;
        margin-left: 12px;
      }
    }

    .side-nav__footer-brand {
      padding: 10px 0;
      transition: padding 1ms linear 200ms;

      &-slogan {
        opacity: 0.4;
      }
    }

    .side-nav__list-item-badge {
      &--dot.badge {
        &--small {
          transform: scale(0);
        }
      }

      &--with-number.badge {
        &--small {
          transform: scale(1);
          transition: transform 100ms ease-in-out 230ms;
        }
      }
    }
  }
}
