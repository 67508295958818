@mixin striped-background($accent-color, $background-color) {
  background-image: linear-gradient(
    135deg,
    $accent-color 6.25%,
    $background-color 6.25%,
    $background-color 50%,
    $accent-color 50%,
    $accent-color 56.25%,
    $background-color 56.25%,
    $background-color 100%
  );
  background-size: 8px 8px;
}

@mixin reset-all-defaults {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin shadow($color, $horizontal, $vertical, $blur1, $blur2) {
  box-shadow: rgba($color, 0.117647) $horizontal $vertical $blur1,
    rgba($color, 0.117647) $horizontal $vertical $blur2;
}

@mixin size($width, $height) {
  height: $height;
  width: $width;
}
