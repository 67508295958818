@import "../../../styles/utils";

.user {
  &__loader {
    &--wrapper {
      .first-col,
      .second-col,
      .third-col {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }

      .second-col,
      .third-col {
        .content-loader {
          &:nth-child(4) {
            margin-top: 10px;
          }
        }
      }

      .first-col {
        .content-loader {
          &:nth-child(3) {
            margin-top: 40px;
          }

          &:nth-child(5) {
            margin-top: 10px;
          }
        }
      }

      .second-col {
        .content-loader {
          &:nth-child(6) {
            margin-top: 10px;
          }
        }
      }

      .third-col {
        align-items: flex-end;
      }

      .fourth-col {
        column-gap: 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
