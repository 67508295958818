@import "../../styles/utils.scss";

.super-office-details {
  .card {
    &__header {
      &--title {
        margin-bottom: 20px;
      }

      &-text {
        font-weight: 700;
      }
    }

    &__body-section {
      font-weight: 400;
    }
  }

  &__basic {
    display: flex;
    justify-content: space-between;
  }

  &__addresses {
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;

    &-block {
      flex: 1;
    }
  }

  &__container {
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;

    &-element {
      flex: 1;
    }
  }

  &__contacts {
    &-phone,
    &-website {
      display: flex;
      justify-content: space-between;
    }

    &-website {
      margin-top: 33px;
    }
  }

  .address {
    &__name {
      display: flex;
      justify-content: space-between;
    }

    &__details {
      display: flex;
      justify-content: space-between;
      margin-top: 33px;
    }
  }

  &__market-data,
  &__interest {
    display: flex;
    flex-wrap: wrap;

    .attribute-alignment {
      margin-bottom: 20px;
      width: 50%;
    }
  }

  &__communication-mode {
    &-language {
      margin-bottom: 33px;
    }
  }
}

.attribute-alignment {
  display: flex;
  flex-direction: column;

  span {
    &:first-of-type {
      color: $cool-gray-66;
      margin-bottom: 10px;
    }
  }
}
