@import "../../../../../styles/utils";

.gardening {
  &__card {
      border: 1px solid $blueish-black-16;
      border-radius: 4px;
      margin: 20px;

      .input {
        display: flex;
        justify-content: space-between;
      }
  }
}
