@import "../../styles/utils";

.forget-password {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__button {
    border-radius: 4px;
    margin-top: 46px;
    width: 250px;
  }

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    right: 600px;

    &-email {
      margin-top: 55px;
      width: 250px;
    }

    &-password {
      margin-top: 45px;
      width: 250px;

      &--label {
        display: flex;
        justify-content: space-between;
        width: 250px;

        &__forgot-password {
          color: $dark-red;
          cursor: pointer;
        }
      }
    }
  }

  &__horizontal-line {
    margin-top: 40px;
    opacity: 0.3;
    width: 250px;
  }

  &__registration {
    margin-top: 20px;

    &--button {
      color: $dark-green;
      cursor: pointer;
      font-weight: 700;
    }
  }

  &__enter-email {
    margin-top: 30px;
    max-width: 700px;
  }
}

.set-password {
  &__form {
    margin-top: 30px;

    &-password {
      margin: 0 auto;
      margin-bottom: 20px;
      width: 250px;
    }

    &-button {
      width: 250px;
    }

    &-error {
      margin-bottom: 20px;

      .rcl-alert {
        padding: 10px;

        &-container {
          justify-content: center;
        }
      }
    }
  }
}

.verify-pin {
  &__form {
    margin-top: 30px;

    &-pin-code {
      margin-bottom: 30px;
    }

    &-explanation {
      margin-bottom: 20px;
    }

    &-submit {
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
