@import "../../../styles/utils";

.product-declarations {
  &__header {
    display: flex;
    justify-content: space-between;

    &-title {
      font-size: $font-16;
    }

    &-actions {
      align-items: center;
      display: flex;

      &-complain {
        margin-right: 8px;
      }
    }

    &--title {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      margin-bottom: 5px;
    }

    &--subtitle {
      font-size: 14px;
      font-style: normal;
      font-weight: $font-weight-200;
      line-height: 20px;
    }

    &-message {
      font-size: 12px;
      font-weight: $font-weight-200;
      line-height: 14px;
      margin-right: 10px;
      max-width: 440px;
      text-align: right;
    }

    &-period {
      font-size: $font-16;
      font-weight: $font-weight-200;
      line-height: 21px;
    }

    &-text {
      &--bold {
        font-size: $font-16;
        font-weight: $font-weight-700;
      }
    }
  }

  &__empty-draft {
    .empty-content-placeholder--with-card {
      margin: 20px 0;
      min-height: 100px;
    }
  }

  &__content {
    &-card {
      margin-top: 20px;

      &--title {
        font-size: 14px;
        margin: 8px 0;
      }
    }
  }

  &__draft {
    &-empty-card {
      margin-top: 20px;
    }
  }

  &__spinner {
    align-items: center;
    display: flex;
    height: 45vh;
    justify-content: center;
  }

  &__form {
    &-action {
      button {
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
    &--margin-top {
      margin-top: 20px;
    }
  }

  &__complain-view {
    display: flex;
    flex-direction: column;

    &-header {
      font-size: 14px;
      font-weight: $font-weight-700;
      line-height: 21px;
    }

    &-body {
      margin-top: 10px;
    }

    &--tag-wrapper {
      align-items: center;
      display: flex;

      .icon-button {
        width: 1rem;
      }
    }

    &--date {
      position: relative;
      right: 24px;
    }

    &--vertical-line {
      background-color: $blueish-black-24;
      height: 14px;
      margin: 0 8px;
      width: 2px;
    }
  }

  &__row {
    &-view {
      cursor: pointer;
      font-weight: $font-weight-600;
      margin-left: 10px;
    }
  }
}

.product-form {
  &__subtitle {
    font-size: 12px;
    margin-bottom: 5px;

    &--bold {
      font-weight: bold;
    }
  }

  &__alert {
    align-items: flex-start;
    display: flex;

    &-card {
      margin: 10px 0 20px 0;
      padding: 10px 5px;
    }

    &-text {
      font-weight: $font-weight-400;

      &--bold {
        font-weight: $font-weight-700;
      }
    }

    .icon {
      margin-top: 2px;
      
      &__content {
        background-color: $warning;
        margin-right: 10px;

        svg {
          fill: $white;
        }
      }
    }
  }

  &__field {
    &--input {
      margin: 20px 0;
    }

    &--button {
      margin-right: 8px;
    }
  }
}
