@import "../../../styles/utils";

.topnav {
  align-items: center;
  background-color: $white;
  box-shadow: 0 1px 3px $cool-gray-91;
  box-sizing: border-box;
  color: $blueish-black;
  display: flex;
  height: 60px;
  padding: 9px 12px;
  width: 100%;
  z-index: 11;

  > * {
    box-sizing: border-box;
  }

  &__back-button,
  &__action {
    &.icon-button,
    .icon-button {
      height: 2rem;
      width: 2rem;

      .icon-button__label {
        background-color: $secondary-accent;
        font-size: 12px;
        font-weight: 500;
        height: auto;
        left: 50%;
        padding: 2px 5px;
        transform: translate(-50%, 0);

        p {
          line-height: 1rem;
          margin-block-end: 0;
          margin-block-start: 0;
        }
      }

      .icon-button__icon {
        font-size: 1.25rem;
        position: relative;
      }
    }

    .icon-button__badge {
      background-color: $danger;
      right: -2px;
      top: -2px;
    }
  }

  &__action {
    align-items: center;
    display: flex;
    margin: 0 10px 0 auto;
    position: relative;

    & ~ & {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;

      .icon-button {
        &__label {
          left: auto;
          right: 0;
          transform: translate(0, 0);
        }
      }
    }
  }

  &__back-button {
    &.icon-button {
      .icon-button__label {
        left: 0;
        transform: translate(0, 0);
      }
    }
  }

  .avatar {
    &__content {
      height: 1.5rem;
      width: 1.5rem;

      &-initials {
        font-size: 0.75rem;
      }
    }
  }

  .breadcrumbs {
    align-self: flex-end;
    width: auto;
  }

  &__page-header {
    margin-left: 10px;
    margin-right: 8px;
  }
}
