@import "../../../styles/utils";

.legend {
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 10%;

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__label {
    font-size: $font-14;
    font-weight: $font-weight-400;
    margin-left: 10px;
  }

  &--success {
    background: $pastal-green;
  }

  &--warning {
    background: $warning;
  }

  &--danger {
    background: $normal-red;
  }

  &--small {
    border-radius: 100px;
    height: 7px;
    width: 18px;
  }

  &--normal {
    height: 14px;
    width: 36px;
  }
}
