@import "../../../styles/utils";

.avatar-upload {
  border-radius: 8px;
  height: 393px;
  max-height: 393px;
  position: relative;
  width: 310px;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__title {
    font-weight: 500;
  }

  &__label {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 40px;
    width: 155px;

    svg {
      fill: $cool-gray-92;
    }
  }

  &__upload-title {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
  }

  &__upload-sub-title {
    font-size: 14px;
  }

  &__dropzone {
    align-items: center;
    background-color: $astronomical-silver;
    border: 2px dashed $cool-gray-88;
    border-radius: 2px;
    color: $cool-gray-80;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 270px;
    justify-content: center;
    outline: none;
    padding: 20px;
    transition: border 0.24s ease-in-out;

    &--active {
      border-color: $downhill-violet;
    }
  }

  .ReactCrop {
    max-height: 320px;
  }

  &__footer {
    bottom: 0;
    display: flex;
    justify-content: space-between;
    position: absolute;

    button {
      margin-right: 5px;
    }
  }
}
