@import "../../../styles/utils";

.rcl-stacked-items {
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  > * {
    box-sizing: border-box;
  }

  .rcl-popover__parent {
    width: 100%;
  }

  &--one {
    .rcl-stacked-items__display-items {
      &--tiny {
        width: auto;
      }

      &--small {
        width: 32px;
      }

      &--normal {
        width: 40px;
      }

      &--large {
        width: 56px;
      }

      &--huge {
        width: 80px;
      }

      &--xl {
        width: 120px;
      }

      &--xxl {
        width: 136px;
      }
    }
  }

  &__display {
    align-items: center;
    display: flex;

    &-items {
      align-items: center;
      color: $light-violet;
      display: flex;
      width: 100%;
    }

    &-item {
      border: 2px solid $white;
      border-radius: 50%;
      position: relative;

      &:nth-child(2) {
        right: 20%;
      }

      &:nth-child(3) {
        right: 40%;
      }
    }

    &-item-amount {
      align-items: center;
      color: $info;
      cursor: pointer;
      display: inline-flex;
      margin-left: 10px;
      position: relative;

      &--tiny {
        font-size: 0.75rem;
      }

      &--small {
        font-size: 0.875rem;
      }

      &--normal {
        font-size: 1rem;
      }

      &--large {
        font-size: 2rem;
      }

      &--huge {
        font-size: 2.5rem;
      }

      &--xl {
        font-size: 3rem;
      }

      &--xxl {
        font-size: 4rem;
      }
    }
  }

  &__list {
    box-shadow: 0 3px 10px $cool-gray-84;

    &-item {
      border-radius: 3px;
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 10px;
      padding: 5px;
      width: 100%;

      &:hover {
        background-color: $cool-gray-96;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__empty {
    align-items: center;
    display: inline-flex;
    font-size: 16px;

    img {
      height: 2.5rem;
      margin-right: 5px;
      padding: 2px;
      width: auto;
    }

    &--tiny {
      font-size: 12px;

      img {
        height: 24px;
      }
    }

    &--small {
      font-size: 14px;

      img {
        height: 32px;
      }
    }

    &--large {
      font-size: 32px;

      img {
        height: 56px;
      }
    }

    &--huge {
      font-size: 40px;

      img {
        height: 80px;
      }
    }

    &--xl {
      font-size: 48px;

      img {
        height: 120px;
      }
    }

    &--xxl {
      font-size: 64px;

      img {
        height: 136px;
      }
    }
  }
}
