@import "../../styles/utils";

.invoice {
  &__list-page {
    padding: 20px;
  }

  &__buttons {
    margin-top: 5px;

    &-cancel {
      margin-left: 10px;
    }
  }

  &__time-picker {
    margin: 10px 0 20px;
  }

  &__checkbox {
    margin-top: 10px;
  }

  &__alert {
    margin-top: 10px;

    .icon {
      background-color: $warning;
      border-radius: 50%;
      height: 30px;
      justify-content: center;
      width: 30px;

      svg {
        fill: $white;
        height: 4px;
        width: 4px;
      }
    }
  }

  &__field-error {
    color: $normal-red;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 5px;
  }

  &__list {
    &--top,
    &--header {
      align-items: center;
      display: flex;
    }

    &--date {
      font-size: $font-14;
      margin-top: 2px;
    }

    &--top {
      margin-left: 20px;
      margin-top: 20px;
    }

    &--content {
      align-items: center;
      color: $blueish-black;
      display: flex;
      font-size: $font-14;
      margin-top: 2px;

      &_divider {
        gap: 10px;
        margin-top: 5px;

        > div {
          border-right: 1px solid $blueish-black-16;
          line-height: 16px;
          padding-right: 10px;
        }

        > div:last-child {
          padding-right: 0;
          border: none;
        }
      }
    }

    &--title {
      font-size: $font-20;
      font-weight: $font-weight-700;
    }
  }

  &__release-date {
    font-size: $font-14;
    font-weight: $font-weight-700;
    margin-bottom: 10px;
  }

  &__current-date {
    font-size: $font-14;
  }

  &__changed-date {
    font-size: $font-12;
    margin-top: 20px;
  }

  &__reject-button {
    margin-right: 10px;
  }

  &__release-button {
    margin-top: 20px;
  }

  &-wrapper {
    padding: 16px 0;
  }

  &__pagination {
    margin-top: 20px;
  }

  &__correct {
    &--date-footer {
      font-size: $font-14;
      margin-top: 10px;
    }

    &--content {
      margin-top: 20px;
    }

    &--button {
      display: flex;
      margin-top: 20px;

      > button:first-child {
        margin-right: 10px;
      }
    }
  }

  &__popover-download {
    padding: 12px 4px;
    width: 100%;

    &:hover {
      background-color: $blueish-black-8;
    }
  }

  &__header {
    &--wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    &--heading {
      color: $blueish-black;
      font-size: $font-20;
      font-weight: $font-weight-700;
      margin-left: 12px;
    }

    &__right-buttons {
      column-gap: 10px;
      display: flex;
    }
  }

  &__line-card {
    padding-left: 20px;

    .table {
      padding: 0;

      .table__row {
        border: none;
      }
    }
  }

  &-details {
    &__header {
      align-items: center;
      display: flex;
      margin-bottom: 20px;
    }

    &--title {
      display: block;
      font-size: $font-16;
      font-weight: $font-weight-700;
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      row-gap: 8px;

      &--label {
        font-weight: $font-weight-700;
      }

      &--value {
        font-weight: $font-weight-400;
      }
    }

    &__horizontal-line {
      border: 1px solid $cool-gray-95;
      margin: 20px 0;
    }

    &__banking-information {
      font-weight: $font-weight-400;

      &--header {
        align-items: center;
        column-gap: 10px;
        display: flex;

        &-icon {
          border: 1px solid $cool-gray-85;
          border-radius: 50%;
          padding: 8px;
        }

        .icon--default svg {
          fill: black;
        }
      }
    }

    &__bank-details {
      margin-bottom: 10px;
      word-break: break-all;

      &--title {
        font-weight: $font-weight-700;
        margin-right: 4px;
      }

      &--block-element {
        display: block;
      }

      &--iban,
      &--margin {
        margin-bottom: 20px;
      }
    }
  }

  &__line-item,
  &__organization {
    &--title {
      display: block;
      font-size: $font-16;
      font-weight: $font-weight-700;
      margin-bottom: 20px;
    }
  }

  &-table {
    .table__head {
      > tr > th {
        border-bottom: 2px solid $cool-gray-95;
        padding: 0 20px 8px;
      }
    }

    &--row {
      vertical-align: top;

      td {
        border: none;
        font-weight: $font-weight-400;
      }

      &--border-top {
        td {
          border-top: 2px solid $cool-gray-95;
          font-weight: $font-weight-400;
        }
      }

      &--border-bottom {
        td {
          border-top: none;
          font-weight: $font-weight-400;
          padding-top: 4px;
        }
      }
    }

    .empty-content-placeholder--with-card {
      box-shadow: none;
      min-height: 40vh;
      width: 100%;
    }
  }

  &__summary {
    border-top: 2px solid $cool-gray-95;
    column-gap: 140px;
    display: flex;
    font-weight: $font-weight-400;
    justify-content: flex-end;
    padding: 10px 18px;

    &--border-right {
      margin-left: auto;
      width: 40%;
    }

    &--titles,
    &--values {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      min-width: 100px;
      row-gap: 10px;

      &-bold {
        font-weight: $font-weight-700;
      }
    }
  }

  &__service {
    &--description {
      font-weight: $font-weight-700;
    }

    :nth-child(2) {
      padding: 10px 0;
    }
  }

  &__create-button {
    display: flex;
    justify-content: end;
  }

  &-list {
    &__bulk-action {
      &-wrapper {
        align-items: center;
        color: $florid-tar;
        display: flex;
        font-size: $font-14;
        font-weight: $font-weight-700;
        margin-bottom: 10px;
      }

      &--check-icon {
        margin: 0 6px 0 1px;
      }

      &--select-all {
        border-left: 1px solid $cool-gray-85;
        border-right: 1px solid $cool-gray-85;
        cursor: pointer;
        margin: 0 20px;
        padding: 0 20px;
      }

      &--clear-all {
        cursor: pointer;
      }

      &--button {
        margin-left: 20px;
      }
    }
  }
}
