@import "react-quill/dist/quill.snow.css";
@import "../../../styles/utils";

.text-editor {
  &__container {
    border: 1px solid $periwinkle-gray;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba($tertiary-accent, 0.16);
    box-sizing: border-box;
    color: $big-stone;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: $bolder-font-weight;

    .ql {
      &-toolbar {
        border: none;
        position: relative;

        .rcl-popover__parent {
          font-size: 16px;
        }

        &-error {
          align-items: center;
          bottom: 0;
          display: inline-flex;
          position: absolute;
          right: 8px;
          top: 0;
        }
      }

      &-container {
        border: none;
        font-family: "Open Sans";
        font-size: $font-14;
        font-weight: $bolder-font-weight;
      }

      &-editor {
        min-height: 115px;
      }
    }

    &:hover {
      border-color: $polo-blue;
    }

    &:focus {
      border-color: $polo-blue;
      outline: none;
    }

    &::placeholder {
      color: $santas-gray;
    }

    &--error {
      &,
      &:hover {
        border: 1px solid $danger;
      }
    }
  }

  &__label {
    display: block;
    margin-bottom: 5px;
  }
}

// This is written in the global scope as tooltip gets rendered outside of component (in the body)
.texeditor-ql-toolbar-error {
  &-tooltip {
    &.rcl-tooltip.rcl-popover {
      padding: 6px 10px;

      .rcl-popover__content {
        font-size: 12px;
      }

      .rcl-popover__tip {
        &--bottom-left,
        &--top-left {
          left: $input-tooltip-tip-offset;
        }

        &--bottom-right,
        &--top-right {
          right: $input-tooltip-tip-offset;
        }

        &--left-top,
        &--right-top {
          top: calc(#{$input-tooltip-tip-offset} * 2);
        }

        &--left-bottom,
        &--right-bottom {
          bottom: calc(#{$input-tooltip-tip-offset} * 2);
        }
      }
    }
  }
}
