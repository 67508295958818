@import "../../../styles/utils.scss";

.file-upload {
  &__label {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 40px;
    width: 155px;

    svg {
      fill: $cool-gray-92;
    }
  }

  &__title {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
  }

  &__sub-title {
    font-size: 14px;
  }

  &__dropzone {
    align-items: center;
    background-color: $historic-milky;
    border-color: $light-lemon;
    border-radius: 6px;
    border-style: dashed;
    border-width: 2px;
    color: $blueish-black;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 120px;
    justify-content: center;
    margin-top: 10px;
    outline: none;
    padding: 20px;
    transition: border 0.24s ease-in-out;

    &--active {
      border-color: $downhill-violet;
    }
  }

  &__file-list {
    margin-top: 8px;
  }

  &__file {
    align-items: center;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-top: 8px;
    padding: 8px 0;

    .icon-button {
      height: 8px;
      margin: 0;
      width: 38px;
    }
  }

  &__vertical-line {
    background-color: $blueish-black-16;
    height: 16px;
    margin: 0 8px;
    width: 2px;
  }

  &__file-name {
    display: flex;
    font-size: $font-14;
    font-weight: $font-weight-700;

    .icon-button {
      justify-content: left;
      padding: 8px 0;
    }

    &--text {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
