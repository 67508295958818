@import "~reflex-grid/scss/reflex";
.app {
  &__main {
    font-family: "Open Sans", sans-serif;
    height: 90vh;
    margin-left: 65px;
    padding-top: 60px;
  }
}

.flex {
  display: flex;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.font-20 {
  font-size: 20px;
}
