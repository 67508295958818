@import "../../../../styles/utils";

.negative-reports {
  &__container {
    margin: 20px 0;
  }

  &__title {
    font-size: $font-16;
    font-weight: $font-weight-700;
    margin: 20px 0;
    text-transform: capitalize;
  }

  &__header {
    &-wrapper {
      display: flex;
      font-size: $font-14;
      font-weight: $font-weight-400;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &-text,
    &-dayleft {
      font-weight: $font-weight-600;
    }

    &-status,
    &-date {
      font-weight: $font-weight-400;
    }
  }

  &__left-panel {
    box-sizing: border-box;
    font-weight: $font-weight-400;
    letter-spacing: normal;
    padding: 20px;
    width: 50%;
    word-spacing: normal;
  }

  &__right-panel {
    box-sizing: border-box;
    font-weight: $font-weight-400;
    letter-spacing: normal;
    padding: 20px;
    position: relative;
    width: 50%;
    word-spacing: normal;

    &::before {
      background-color: rgba(0, 57, 63, 0.16);
      bottom: 20px;
      content: "";
      left: -1px;
      position: absolute;
      top: 20px;
      width: 2px;
    }
  }

  &__item {
    &-header {
      margin-bottom: 10px;
    }

    &-list {
      margin: 0;
      padding-left: 20px;

      &__wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      &__goods-list:not(:last-of-type) {
        border-bottom: 1px solid $blueish-black-16;
      }
    }
  }

  &__form-description {
    margin: 8px 0;
  }

  &__answer {
    display: flex;
    justify-content: space-between;
  }
}

.random-goods-declarations {
  &__list {
    margin: 0;
    padding: 0;
    padding-left: 20px;
  }

  &__values {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__wrapper:not(:last-of-type) {
    border-bottom: 1px solid $blueish-black-16;
    margin-bottom: 10px;
  }
}
.logo-attachment-task {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &-zero {
      margin-bottom: 0;
    }

    &--title {
      font-weight: $font-weight-600;
    }

    &--files {
      align-items: center;
      display: flex;
    }

    &--upload-btn {
      margin-left: 8px;
    }
  }

  &__file {
    &-info {
      display: flex;

      &--name {
        margin-left: 8px;
        max-width: 200px;
        min-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-wrapper {
      align-items: center;
      border: 1px solid $blueish-black-16;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      padding: 8px;
    }

    &-dynamic-margin:nth-child(odd) {
      padding: 5px;
      padding-left: 0;
    }

    &-dynamic-margin:nth-child(even) {
      padding: 5px;
      padding-right: 0;
    }
  }

  &__upload-errors {
    color: red;
    padding: 8px;
  }
}

.custom-yes-no-input {
  .yes-no-input__container {
    display: block;
  }

  .yes-no-input__options {
    margin-top: 20px;
  }
}
