@import "../../../../styles/utils.scss";

.copy-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: $font-weight-600;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__error-text {
    color: $danger;
    font-size: $font-14;
    margin-right: 10px;
  }
}

.copy-declarations {
  &__data {
    width: 100%;
  }

  &__pagination {
    margin-top: 10px;
  }

  &__action-button {
    margin-right: 10px;
  }
}
