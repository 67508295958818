.empty-placeholder {
  font-weight: 400;

  .card--v2 {
    &__empty-text {
      display: flex;
      justify-content: center;
    }
  }
}
