@import "../../../styles/utils";

.time-picker {
  &__container {
    align-items: center;
    border: 1px solid $blueish-black-16;
    box-shadow: 0 1px 3px 0 $blueish-black-16;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &__header {
    font-size: $font-14;
    margin-bottom: 5px;
  }

  &__up-arrow {
    bottom: 10px;
    position: relative;
    user-select: none;

    &--hour {
      left: 10px;
    }

    &--minute {
      left: 22px;
    }
  }

  &__down-arrow {
    position: relative;
    top: 80px;
    user-select: none;

    &--hour {
      right: 23px;
    }

    &--minute {
      right: 11px;
    }
  }

  &__footer {
    margin-top: 80px;
  }
}


.react-time-picker {
  &__wrapper {
    border: none;
    user-select: none;
  }

  &__inputGroup {
    &__divider {
      margin: 0 18px;
    }
  }

  &__button {
    height: 20px;
    user-select: none;
    width: 20px;
  }

  &__inputGroup {
    &__hour {
      margin-left: -0.01em;
      margin-right: 0.06em;
    }

    &__minute {
      margin-left: 0.02em;
    }

    &__input {
      &--hasLeadingZero {
        height: 36px;
        margin: 0;
        margin-left: -0.54em;
        min-width: 34px;
        padding: 0;
        padding-left: 8px;
      }
    }

    &__leadingZero {
  left: 8px;
      position: relative;
    }

  }
}

.react-time-picker {
  &__inputGroup__hour, &__inputGroup__minute {
    border: 1px solid $blueish-black-16;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 $blueish-black-16;
    box-sizing: border-box;
    height: 36px;
    min-width: 34px;
    outline: none;
    text-align: center;
  }

}

