@import "../../../styles/utils.scss";

$gap-between-button-and-popup-menu: 8px;
$distance-of--popup-menu: calc(100% + #{$gap-between-button-and-popup-menu});

.rcl-menu {
  align-items: center;
  display: flex;

  &__item {
    margin: 0;
    padding: 0;
  }

  &__button {
    border: none;
    height: 30px;
    width: 30px;

    .icon-button__icon {
      font-size: 24px;
    }
  }

  &__list {
    &-item {
      background-color: $white;
      border-radius: 3px;
      color: $blueish-black;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.2s ease;

      > * {
        align-items: center;
        color: $black;
        display: flex;
        outline: none;
        padding: 12px 4px;
        text-decoration: none;
      }

      svg {
        font-size: 20px;
      }

      &:hover {
        background-color: $blueish-black-8;
      }

      &:active {
        background-color: $blueish-black-16;
        font-weight: $font-weight-700;

        > * {
          color: $white;
        }

        svg {
          fill: $white;
        }
      }

      &--icon-left .icon {
        margin-left: 0;
        margin-right: 10px;
        order: -1;
      }

      &--icon-right .icon {
        margin-left: auto;
        margin-right: 0;
        order: 1;
      }
    }
  }
}
