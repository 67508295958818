@import "../../../../../styles/utils";

.modal__footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  &--left {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: end;
  }

  .button {
    font-weight: $bold-font-weight;
    margin-right: 6px;
    padding: 6px 30px;
  }
}
