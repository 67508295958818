@import "../../../styles/utils";

.organization-education {
  &__assignee {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-user {
      align-items: center;
      display: flex;

      &-label {
        font-weight: $boldest-font-weight;
        margin-right: 10px;
      }
    }

    &-action {
      button {
        margin-right: 10px;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    &-eligibility {
      margin-bottom: 10px;
    }

    &-set {
      &-for {
        font-size: 12px;
        margin-bottom: 5px;
      }

      &-charta-validity {
        margin-bottom: 20px;
      }

      &-action {
        margin-top: 20px;

        button {
          margin-right: 10px;
        }
      }
    }
  }

  &__declarations {
    margin-top: 20px;

    &-not-created {
      margin-bottom: 20px;
    }
  }

  &--margin-top {
    margin-top: 20px;
  }

  &--margin-right {
    margin-right: 10px;
  }

  &__table {
    padding: 20px 0;

    &-row {
      &-actions {
        display: flex;
        justify-content: center;
      }

      &--download-icon {
        cursor: pointer;
        justify-content: center;

        .icon {
          svg {
            fill: $dark-blue;
          }
        }
      }
    }
  }

  &__complain {
    &-description {
      height: 84px;
    }
  }
}
