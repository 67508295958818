@import "styles/utils";

.modal {
  &__warning,
  &__success,
  &__loader,
  &__error,
  &__filter {
    .modal {
      &__container {
        max-width: 396px;
      }

      &__content {
        justify-content: center;
        margin-top: 10px;
        text-align: center;
        white-space: pre-wrap;
      }

      &__title {
        align-items: center;
        flex-flow: column;
        justify-content: center;
      }
    }

    &--title {
      &-name {
        font-size: 16px;
        font-weight: $font-weight-700;
        margin-top: 10px;
      }
    }
  }

  &__success,
  &__error {
    .modal {
      &__container {
        min-height: auto;
      }
    }

    &-title {
      &-header {
        font-size: 16px;
        font-weight: $font-weight-700;
        margin-top: 10px;
      }
    }

    &-body {
      margin-top: 10px;
    }
  }

  &__success {
    .modal {
      &__title {
        .icon {
          &__content {
            background-color: $pastal-green;

            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }

  &__warning {
    .modal {
      &__title {
        .icon {
          &__content {
            background-color: $warning;

            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }

  &__error {
    .modal {
      &__title {
        .icon {
          &__content {
            background-color: $normal-red;

            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }

  &__filter {
    .modal {
      &__container {
        height: fit-content;
        max-width: fit-content;
        min-height: fit-content;
        padding: 20px;
      }

      &__title {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 8px;
      }

      &__content {
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 5px;
        text-align: left;

        &--filter-container {
          width: 100%;
        }

        &__title {
          display: block;
          font-weight: $font-weight-700;
        }

        &--reset-all {
          cursor: pointer;
          font-weight: $font-weight-400;
        }

        &__filter-set {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          width: 100%;

          > :first-child {
            padding-right: 20px;
          }

          > :not(:first-child) {
            padding: 0 20px;
          }

          > :not(:last-child) {
            border-right: $cool-gray-93 1px solid;
          }
        }

        &__date-range {
          align-items: center;
          border: 1px solid $cool-gray-88;
          border-radius: 4px;
          display: grid;
          font-size: 13px;
          grid-template-columns: 7.4fr 1.1fr 7.3fr 1fr;
          margin-bottom: 10px;
          min-height: 20px;
          padding: 4px 12px;

          input {
            border: none;
            outline: none;
            width: 120px;
          }

          svg {
            font-size: 0.9rem;
          }
        }

        &__checkbox {
          &--search {
            margin-bottom: 20px;
            width: 100%;
          }

          &-container {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            width: 100%;
          }

          &--scrollable {
            height: 220px;
            overflow-y: auto;
          }
        }

        &--title-container {
          align-items: center;
          display: flex;
          height: 26px;
          margin-bottom: 5px;
        }

        &--checkbox-badge {
          margin-left: 6px;
          width: fit-content;

          .badge__number {
            font-size: 14px;
            font-weight: $font-weight-600;
            margin-bottom: 2px;
            padding: 0 9px;
          }
        }

        &__action-buttons {
          cursor: pointer;
          display: block;
          font-weight: $font-weight-400;
          margin-bottom: 20px;
        }

        &__checkbox--option {
          margin: 0 10px 16px;
        }
      }
    }

    &--title {
      &-name {
        font-size: 20px;
        margin-top: 0;
      }
    }

    &--footer {
      justify-content: flex-start;

      .button {
        margin-right: 10px;
        padding: 6px 16px;
      }
    }
  }
}
