@import "../../../styles/utils";

.spinner {
  border-radius: 3px;
  padding: 10px;

  &__icon {
    animation: rotate 0.7s linear infinite;
    display: block;
    transform-origin: center;

    circle {
      fill: none;
      stroke-dasharray: 88, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
    }

    &--secondary-accent {
      circle {
        stroke: $secondary-accent;
      }
    }

    &--danger {
      circle {
        stroke: $danger;
      }
    }

    &--success {
      circle {
        stroke: $success;
      }
    }

    &--warning {
      circle {
        stroke: $warning;
      }
    }
  }

  &--xxs {
    padding: 6px;

    svg {
      width: 0.75em;
    }

    circle {
      stroke-width: 5.5;
    }
  }

  &--xs {
    padding: 8px;

    svg {
      width: 1em;
    }

    circle {
      stroke-width: 5;
    }
  }

  &--tiny {
    svg {
      width: 1.6em;
    }

    circle {
      stroke-width: 4.5;
    }
  }

  &--small {
    svg {
      width: 3.75em;
    }

    circle {
      stroke-width: 3.5;
    }
  }

  &--large {
    svg {
      width: 4.75em;
    }

    circle {
      stroke-width: 3;
    }
  }

  &--huge {
    svg {
      width: 5.75em;
    }

    circle {
      stroke-width: 2.5;
    }
  }

  &--secondary-accent {
    background-color: $tertiary-accent-light;
  }

  &--danger {
    background-color: $danger-light;
  }

  &--success {
    background-color: $success-light;
  }

  &--warning {
    background-color: $warning-light;
  }

  &--neutral {
    background-color: transparent;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
}
