.avatar-upload-modal {
  .modal {
    &__container {
      max-width: 360px;
    }
  }

  .avatar-upload {
    margin: 0 auto;
  }
}
