@import "../../../styles/utils";

.messages {
  &__confirm-button {
    margin-top: 20px;
  }
}

.message-list__item {
  align-items: center;
  background: $white;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba($tertiary-accent, 0.16);
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  min-height: auto;
  padding: 16px 20px;
  width: 100%;

  &-avatar {
    .avatar__text {
      display: none;
    }
  }

  .message-list__item-details {
    display: none;
  }

  &-title {
    color: $primary-text;
    margin-right: auto;
    padding-top: 2px;

    @include viewport-is(tab-mobile) {
      order: 2;
    }

    &--checked {
      color: $posh-sorrel;
      text-decoration: line-through;
    }
  }

  &-checkbox,
  &-tag {
    align-items: center;
    display: flex;
    margin-right: 15px;
  }

  &-tag {
    @include viewport-is(tab-mobile) {
      margin-right: auto;
    }
  }

  &-date {
    color: $blueish-black;
    font-size: 14px;
    font-weight: $font-weight-400;
    margin-right: 15px;
    padding-top: 2px;

    &--sent-at {
      color: rgba($blueish-black, 0.4);
    }

    &--with-no-margin {
      margin-right: 0;
    }
  }

  &-icons {
    margin-left: 11px;
  }

  &--expanded {
    .message-list__item-details {
      display: block;
      width: 100%;
    }

    .message-list__item {
      &-largeTitle {
        color: $florid-tar;
        font-size: 20px;
        font-weight: $bold-font-weight;
        margin-bottom: 6px;
        margin-top: 26px;
        text-align: left;
      }

      &-assosiatedOrder {
        color: $santas-gray;
        font-size: 12px;
        margin-bottom: 30px;
        text-align: left;
      }

      &-description {
        color: $florid-tar;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 33px;
        text-align: left;
      }

      &-avatars {
        display: flex;
        justify-content: space-between;

        &-assignee,
        &-reporter {
          display: flex;
          flex-direction: column;

          &-name {
            margin-bottom: 20px;
          }

          &-label {
            color: $disabled;
            font-size: 12px;
            font-weight: $bold-font-weight;
            margin-bottom: 10px;
            margin-top: 4px;
          }

          .avatar__text-name {
            color: $florid-tar;
          }
        }
      }

      &-times {
        display: flex;
        justify-content: space-between;

        > div {
          color: $santas-gray;
          font-size: 12px;
        }
      }
    }
  }
}
